import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P61-E1",
    audio: "Audios/Page61/89.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 8.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='89' src='Audios/Page61/89 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page60/E3/1.jpg",
          audioUrl: "Audios/Page60/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/2.jpg",
          audioUrl: "Audios/Page60/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E3/3.jpg",
          audioUrl: "Audios/Page60/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/4.jpg",
          audioUrl: "Audios/Page60/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P61-E2",
    audio: "Audios/Page61/90.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "95%",
      // marginTop: 300,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='90' src='Audios/Page61/90 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page61/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page61/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page61/E2/3.jpg",
          // audioUrl: "Audios/Page61/90 01.mp3",
        },
        { url: "img/FriendsPlus/Page61/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page61/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page61/E2/6.jpg",
          // audioUrl: "Audios/Page61/90 02.mp3",
        },
        { url: "img/FriendsPlus/Page61/E2/7.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page61/E2/8.jpg" }],
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P61-E3",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page61/Key/E3answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 140,
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "3",
        title:
          "Read and write <i>T (true)</i> or <i>F (false)</i>. <img style='height:1cm; width: 2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/1.jpg'/>",
        color: "#203c8f",
        // numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    questions: [
      {
        title: `
        <div style='line-height: 50px'>
          <div style='display: flex'><b>1</b>&ensp;Nam is taller than Thanh.&ensp; <select id=0></select></div>
          <div style='display: flex'><b>2</b>&ensp;Duy is faster than Nam.&ensp; <select id=1></select></div>
          <div style='display: flex'><b>3</b>&ensp;Thanh is faster than Duy.&ensp; <select id=2></select></div>
          <div style='display: flex'><b>4</b>&ensp;Nam is slower than Duy.&ensp; <select id=3></select></div>
          <div style='display: flex'><b>5</b>&ensp;Thanh is taller than Nam.&ensp; <select id=4></select></div>
          <div style='display: flex'><b>6</b>&ensp;Duy is shorter than Thanh.&ensp; <select id=5></select></div>
        </div>
       
      <img style='height:10cm;margin-top:20px' src='img/FriendsPlus/Page61/E3/1.jpg'/>
          
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["T", "F", "T", "F", "F", "T"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P61-E4",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page61/Key/E4answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 200,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "4",
        title: "Write.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height: 50px'>
          <div style='display: flex'><b>1</b>&ensp;Australia is &ensp;<span style='color:rgb(54,166,215);'> larger than</span>&ensp; (large) Singapore.</div>
          <div style='display: flex'><b>2</b>&ensp;Damb'ri waterfall is # (high) Prenn waterfall.</div>
          <div style='display: flex'><b>3</b>&ensp;Cars are # (fast) bikes.</div>
          <div style='display: flex'><b>4</b>&ensp;Grapes are # (small) apples.</div>
          <div style='display: flex'><b>5</b>&ensp;Ba Be lake is # (deep) Hoan Kiem lake.</div>
          <div style='display: flex'><b>6</b>&ensp;Phu Quoc island is # (large) Con Dao island.</div>
        </div>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "higher than",
          "faster than",
          "smaller than",
          "deeper than",
          "larger than",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P61-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page61/E4/1.jpg" }]],
  },
};

export default json;
