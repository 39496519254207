import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P84-E1",
    // audio: "Audios/Page61/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page84/E1/1.jpg" }]],
  },
  2: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P84-E2",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page84/Key/E2answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 800,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    textareaStyle: {
      paddingTop: 5,
      // background: 'none',
      width: 800,
      // fontSize: 22,
      // height: 70,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Write sentences about life in 2075.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    isHello: true,
    KeyMau: true,
    questions: [
      {
        title: `
        <div style='line-height: 50px'>
          <div><span style='color:rgb(54,166,215);'>People will work on the moon. They will ...</span></div>
          <div><textarea id='0' rows='8'></div>
          
        </div>
      
      
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "",
          // "They will work on the moon. They will fly to Venus by spaceship. They will wear astronauts's clothes. They will travel around Earth on a supersonic plane. They will take a tour around space.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P84-E3",
    audio: "Audios/Page84/127.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and sing. <headphone name='127' src='Audios/Page84/127 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page84/E3/1.jpg",
          // audioUrl: "Audios/Page84/candy.mp3",
        },
      ],
    ],
  },
  4: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P84-E4",
    // audio: "Audios/Page84/audio-e3.mp3",
    video:
      "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/A trip to the moon.mp4",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "88%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "4",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page84/E3/1.jpg",
          // audioUrl: "Audios/Page84/candy.mp3",
        },
      ],
    ],
  },
};

export default json;
