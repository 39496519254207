import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P88-E1",
    audio: "Audios/Page88/133.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='133' src='Audios/Page88/133 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page88/E1/1.jpg",
          audioUrl: "Audios/Page88/video game.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/3.jpg",
          audioUrl: "Audios/Page88/tablet.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/5.jpg",
          audioUrl: "Audios/Page88/singer.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/7.jpg",
          audioUrl: "Audios/Page88/movie star.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page88/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page88/E1/9.jpg",
          audioUrl: "Audios/Page88/dvd.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/11.jpg",
          audioUrl: "Audios/Page88/remote control.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/13.jpg",
          audioUrl: "Audios/Page88/tv show.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/15.jpg",
          audioUrl: "Audios/Page88/smart phone.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P88-E2",
    audio: "Audios/Page88/134.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='134' src='Audios/Page88/134 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page88/E1/1.jpg",
          audioUrl: "Audios/Page88/video game.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/3.jpg",
          audioUrl: "Audios/Page88/tablet.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/5.jpg",
          audioUrl: "Audios/Page88/singer.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/7.jpg",
          audioUrl: "Audios/Page88/movie star.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page88/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page88/E1/9.jpg",
          audioUrl: "Audios/Page88/dvd.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/11.jpg",
          audioUrl: "Audios/Page88/remote control.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/13.jpg",
          audioUrl: "Audios/Page88/tv show.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page88/E1/15.jpg",
          audioUrl: "Audios/Page88/smart phone.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P88-E3",
    audio: "Audios/Page88/135.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 12.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. What did they watch? <headphone name='135' src='Audios/Page88/135 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page88/E3/1.jpg",
          audioUrl: "Audios/Page88/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/2.jpg",
          audioUrl: "Audios/Page88/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page88/E3/3.jpg",
          audioUrl: "Audios/Page88/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/4.jpg",
          audioUrl: "Audios/Page88/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
