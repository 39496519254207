import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Fluency Time",
    id: "SB5-2024-FT-P74-E1",
    audio: "Audios/Page74/109.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen. Read and say. <headphone name='109' src='Audios/Page74/109 tieu-de.mp3'></headphone>",
        color: "#F03D50",
        numberImg: ["c"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page74/E1/1.jpg",
          audioUrl: "Audios/Page74/109 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page74/E1/2.jpg",
          // audioUrl: "Audios/Page74/109 02.mp3",
        },
        {
          url: "img/FriendsPlus/Page74/E1/3.jpg",
          audioUrl: "Audios/Page74/109 02.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time",
    id: "SB5-2024-FT-P74-E2",
    audio: "Audios/Page74/110.mp3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E2answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 180,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 23,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and complete the sentences. <headphone name='110' src='Audios/Page74/110 tieu-de.mp3'></headphone>",
        color: "#F03D50",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display; flex'>
        <div style='line-height: 45px; font-size: 23px'>
          <div style='display: flex'><b>1</b>&ensp;Ben made a poster about dangerous <u style='color: rgb(54,166,215)'> &ensp;animals&ensp;</u>.</div> 
          <div style='display: flex'><b>2</b>&ensp;The spider is # .</div>
          <div style='display: flex'><b>3</b>&ensp;It is # than a hand.</div>
          <div style='display: flex'><b>4</b>&ensp;It's # than a dinner plate.</div>
          <div style='display: flex'><b>5</b>&ensp;It lives in the # .</div>
        </div>
        <img style='height:10cm;margin-top:20px' src='img/FriendsPlus/Page74/E2/1.jpg'/>
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["large", "larger", "larger", "jungle"],
      },
    ],
  },
  3: {
    unit: "Fluency Time",
    id: "SB5-2024-FT-P74-E1",
    // audio: "Audios/Page74/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "3",
        title: "Ask and answer.",
        color: "#F03D50",
        numberImg: ["c"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page74/E3/1.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page74/E3/2.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
    ],
  },
};

export default json;
