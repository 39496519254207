import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P37-E1",
    audio: "Audios/Page36/53.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 5.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='53' src='Audios/Page36/53 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page36/E3/1.jpg",
          audioUrl: "Audios/Page36/53 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E3/2.jpg",
          audioUrl: "Audios/Page36/53 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page36/E3/3.jpg",
          audioUrl: "Audios/Page36/53 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E3/4.jpg",
          audioUrl: "Audios/Page36/53 04.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P37-E2",
    audio: "Audios/Page37/54.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='54' src='Audios/Page37/54 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page37/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page37/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page37/E2/3.jpg",
          audioUrl: "Audios/Page37/54 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page37/E2/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page37/E2/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page37/E2/6.jpg",
          audioUrl: "Audios/Page37/54 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page37/E2/7.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page37/E2/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page37/E2/9.jpg",
          audioUrl: "Audios/Page37/54 02.mp3",
        },
        {
          url: "img/FriendsPlus/Page37/E2/10.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page37/E2/11.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P37-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page37/Key/E3answerKey.png",
    component: Circle_Write,

    titleQuestion: [
      {
        num: "3",
        title: "Look, read, and circle",
        color: "#203c8f",
        width: "25cm",
        left: -50,
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid white",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid #00aeef",
        },
        limitSelect: 1,
        listWords: [
          "was / wasn't",
          "had / didn't_have",
          "had / didn't_have",
          "were / weren't",
        ],
        answers: ["0-0", "1-0", "2-4", "3-4"],
        initialValue: ["0-2"],
      },
      Layout: `
        <div style='display:flex;font-size:23px;'>
          <div style='margin-right:200px'>
            <div style='text-align:center;'>
              <img style='height:7cm' src='img/FriendsPlus/Page37/E3/1.jpg'/>  
              <div style='width:7cm'>It <input id=0 type='Circle' /> hot last week.</div>
            </div>
            <div style='text-align:center'>
              <img style='height:7cm' src='img/FriendsPlus/Page37/E3/2.jpg'/>  
              <div style='width:7cm'>He <input id=1 type='Circle' /> ice cream at the beach.</div>
            </div>
          </div>
          <div>
            <div style='text-align:center;'>
              <img style='height:7cm' src='img/FriendsPlus/Page37/E3/3.jpg'/>  
              <div style='width:7cm'>She <input id=2 type='Circle' /> an umbrella yesterday.</div>
            </div>
            <div style='text-align:center'>
              <img style='height:7cm' src='img/FriendsPlus/Page37/E3/4.jpg'/>  
              <div style='width:7cm'>They <input id=3 type='Circle' /> on vacation in Germany.</div>
            </div>
          </div>
      </div>

      `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P37-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page37/Key/E4answerKey.png",
    // styleHint: {
    //   border: "none",
    //   paddingInline: 3,
    //   paddingBlock: "0px",
    //   styleElementHintBox: {
    //     background: "#fee7d2",
    //     marginRight: 20,
    //     padding: "2px 5px",
    //     borderRadius: "10px",
    //   },
    // },
    hintBox: [
      {
        src: ["on", "in", "at"],
        width: 216,
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 120 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Read the story again  and complete the sentences",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:12cm' src='img/FriendsPlus/Page37/E4/1.jpg'/>
              <div style='margin-top:10px'>
                <b>1</b>&ensp;Max and Holly # late.<br>
                <b>2</b>&ensp;# the plane early? Yes, it #.<br>
                <b>3</b>&ensp;Leo and Amy # in Singapore. They # in Australia.<br>
                <b>4</b>&ensp;# they # a bad vacation? No, they #.<br>
                <b>5</b>&ensp;They # a lot of fun.<br>
              </div>
        
      `,
        answer: [
          "were not|weren't",
          "Was",
          "was",
          "were not|weren't",
          "were",
          "Did",
          "have",
          "did not|didn't",
          "had",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P37-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 778,
      paddingTop: 5,
    },
    inputSize: 720,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title: "Write a tourist leaflet. Use the example to help you.",
    //     color: "#203c8f",
    //     numberImg: "a",
    //     left: 80,
    //   },
    // ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img style='height:11cm' src='img/FriendsPlus/Page37/E5/1.jpg'/>
				  
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
