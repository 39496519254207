import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P55-E1",
    audio: "Audios/Page54/79.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 7.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 70,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='79' src='Audios/Page54/79 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page54/E3/1.jpg",
          audioUrl: "Audios/Page54/79 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/2.jpg",
          audioUrl: "Audios/Page54/79 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E3/3.jpg",
          audioUrl: "Audios/Page54/79 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/4.jpg",
          audioUrl: "Audios/Page54/79 04.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P55-E2",
    audio: "Audios/Page55/80.mp3",
    video: "",
    component: UI,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='80' src='Audios/Page55/80 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 70,
    },
    questionImage: [
      [{ url: "img/FriendsPlus/Page55/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page55/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page55/E2/3.jpg",
          audioUrl: "Audios/Page55/80 01.mp3",
        },
        { url: "img/FriendsPlus/Page55/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page55/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page55/E2/6.jpg",
          audioUrl: "Audios/Page55/80 02.mp3",
        },
        { url: "img/FriendsPlus/Page55/E2/7.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page55/E2/8.jpg" }],
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P55-E3",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page55/Key/E3answerKey.png",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 100,
    titleQuestion: [
      {
        num: "3",
        title: "Write.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // isHello: true,
    questions: [
      {
        title: `
      <div style='display:flex'>
        <div style='width:12cm;line-height: 40px'>
        We <sup>1</sup> <u style='color: rgb(67,192,186)'>&ensp;went&ensp;</u>(go) to the 
        museum yesterday. It was very 
        interesting. <br>We   <sup>2</sup>#(see) 
        a lot of dinosaur skeletons. Linh 
          <sup>3</sup>#(buy) a dinosaur 
        model. Mom   <sup>4</sup>#(write) 
        our names in the visitor book. 
        We didn’t want to leave.
        </div>
        <img style='height:7cm' src='img/FriendsPlus/Page55/E3/1.jpg'/>
      </div>
          
        
        `,
        answer: ["saw", "bought", "wrote"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P55-E4",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page55/Key/E4answerKey.png",
    component: T6,
    inputSize: 500,
    titleQuestion: [
      {
        num: "4",
        title: "Read the story again. Answer the questions.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // isHello: true,
    questions: [
      {
        title: `

        
      
      <div><b>1.</b> Where did Max, Amy, and Holly go?<br>#</div>
      <div><b>2.</b> What did Amy buy?<br>#</div>
      <div><b>3.</b> What did they see?<br>#</div>
      <div><b>4.</b> What did Amy think when the dinosaur roared?<br>#</div>
      <div><b>5.</b> What did Max know about the dinosaur?<br>#</div>
        
        `,
        answer: [
          "They went to the dinosaur museum.",
          "She bought a dinosaur model.",
          "They saw dinosaur bones.",
          "She thought it was alive.",
          "He knew it was a model.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P6-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,

    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page55/E5/1.jpg",
        },
      ],
    ],
  },
};

export default json;
