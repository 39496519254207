import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P92-E1",
    audio: "Audios/Page92/140.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "95%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='140' src='Audios/Page92/140 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page92/E1/1.jpg",
          audioUrl: "Audios/Page92/stadium.mp3",
        },
        {
          url: "img/FriendsPlus/Page92/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page92/E1/3.jpg",
          audioUrl: "Audios/Page92/cartoon.mp3",
        },
        {
          url: "img/FriendsPlus/Page92/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page92/E1/5.jpg",
          audioUrl: "Audios/Page92/team.mp3",
        },
        {
          url: "img/FriendsPlus/Page92/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page92/E1/7.jpg",
          audioUrl: "Audios/Page92/quiz program.mp3",
        },
        {
          url: "img/FriendsPlus/Page92/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page92/E1/9.jpg",
          audioUrl: "Audios/Page92/channel.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P92-E2",
    // audio: "Audios/Page92/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "80%",
      marginLeft: 80,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Look at the text. What can you see in the pictures?",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page92/E2/1.jpg",
          // audioUrl: "Audios/Page92/candy.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P92-E3",
    audio: "Audios/Page92/141.mp3",
    exerciseKey: "img/FriendsPlus/Page92/Key/E3answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 200,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='141' src='Audios/Page92/141 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        // numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:18cm;margin-top:2px' src='img/FriendsPlus/Page92/E2/1.jpg'/>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P92-E4",
    // audio: "Audios/Page92/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page92/Key/E4answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 800,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      marginTop: -70,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "4",
        title:
          "Read again and write <i>T</i> (true) or <i>F</i> (false). Then correct the false sentences.",
        color: "#203c8f",
        numberImg: ["b"],
      },
    ],
    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:16.5cm' src='img/FriendsPlus/Page92/E2/1.jpg'/>
        <div style='line-height: 25px; font-size: 22px'>
          <div style='display: flex; margin-bottom: 20px'><b>1.</b>&ensp;Gru is going to take the moon.&ensp; <u style='color: rgb(54,166,215)'>&ensp;T&ensp;</u></div>
          <div style='display: flex'><b>2.</b>&ensp;Jerry is going to chase Tom.&ensp; <select id=3></select></div>
          <div style='margin-bottom: 20px'><input id=0/></div>
          <div style='display: flex'><b>3.</b>&ensp;The soccer game is in Thailand.&ensp; <select id=4></select></div>
          <div style='margin-bottom: 20px'><input id=1/></div>
          <div style='display: flex'><b>4.</b>&ensp;The quiz starts at six o'clock.&ensp; <select id=5></select></div>
          <div style='margin-bottom: 20px'><input id=2/></div>
        </div>

        `,
        // initialValue: ["T"],
        answer: [
          `Tom is going to chase Jerry.`,
          `The soccer game is in Viet Nam.`,
          ``,

          "F",
          "F",
          "T",
        ],
        InputRong: true,
      },
    ],
  },
  5: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P92-E5",
    // audio: "Audios/Page92/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "20%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "5",
        title: "What TV shows do you like watching?",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page64/E5/1.jpg",
          // audioUrl: "Audios/Page92/candy.mp3",
        },
      ],
    ],
  },
};

export default json;
