import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import Select_Image from "../../components/ExcerciseTypes/Select/Select_Image";
const json = {
  1: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P91-E1",
    audio: "Audios/Page91/138.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "78%",
      marginLeft: 100,
      marginBottom: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='138' src='Audios/Page91/138 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page91/E1/1.jpg",
          audioUrl: "Audios/Page91/what are you going to do.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page91/E1/3.jpg",
          audioUrl: "Audios/Page91/are you going to play.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page91/E1/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page91/E1/5.jpg",
          audioUrl: "Audios/Page91/where are they going to go.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page91/E1/7.jpg",
          audioUrl: "Audios/Page91/is she going to eat pizza.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page91/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page91/E1/9.jpg",
        },
        {
          url: "img/FriendsPlus/Page91/E1/10.jpg",
          audioUrl: "Audios/Page91/is he going to outside.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P91-E2",
    audio: "Audios/Page91/139.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 50,
      marginBottom: 50,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='139' src='Audios/Page91/139 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page91/E2/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P91-E3",
    audio: "Audios/Page91/audio-e3.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 50,
      marginBottom: 50,
    },
    titleQuestion: [
      {
        num: "3",
        title: "Read the chant again. Then say.",
        color: "#203c8f",
        numberImg: ["b", "c"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page91/E2/1.jpg",
        },
      ],
    ],
  },
  4: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P91-E4",
    // audio: "Audios/Page72/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page91/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "Say the sentences. Draw arrows. <img style='height:1cm' src='img/FriendsPlus/ListPage/c.jpg'/>",
        color: "#203c8f",
        numberImg: ["b"],
        left: -30,
      },
    ],
    component: Select_Image,

    selectStyle: {
      height: "1cm",
    },
    styleImgSelect: [
      { height: 40, width: 401 },
      { height: 40, width: 610 },
      { height: 40, width: 430 },
      { height: 40, width: 490 },

      { height: 40, width: 380 },
      { height: 40, width: 175 },
      { height: 40, width: 130 },
      { height: 40, width: 125 },
    ],
    selectGroupOption: [
      [
        `img/FriendsPlus/Page91/E4/z1.jpg`,
        `img/FriendsPlus/Page91/E4/z1-1.jpg`,
      ],
      [
        `img/FriendsPlus/Page91/E4/z1.jpg`,
        `img/FriendsPlus/Page91/E4/z1-1.jpg`,
      ],
      [
        `img/FriendsPlus/Page91/E4/z2.jpg`,
        `img/FriendsPlus/Page91/E4/z2-1.jpg`,
      ],
      [
        `img/FriendsPlus/Page91/E4/z2.jpg`,
        `img/FriendsPlus/Page91/E4/z2-1.jpg`,
      ],
      // [
      //   `img/FriendsPlus/Page91/E4/z2.jpg`,
      //   `img/FriendsPlus/Page91/E4/z2-1.jpg`,
      // ],
      // [
      //   `img/FriendsPlus/Page91/E4/z3.jpg`,
      //   `img/FriendsPlus/Page91/E4/z3-1.jpg`,
      // ],
      // [
      //   `img/FriendsPlus/Page91/E4/z4.jpg`,
      //   `img/FriendsPlus/Page91/E4/z4-1.jpg`,
      // ],
      // [
      //   `img/FriendsPlus/Page91/E4/z5.jpg`,
      //   `img/FriendsPlus/Page91/E4/z5-1.jpg`,
      // ],
      // [
      //   `img/FriendsPlus/Page91/E4/z6.jpg`,
      //   `img/FriendsPlus/Page91/E4/z6-1.jpg`,
      // ],
      // [
      //   `img/FriendsPlus/Page91/E4/z7.jpg`,
      //   `img/FriendsPlus/Page91/E4/z7-1.jpg`,
      // ],
      // [
      //   `img/FriendsPlus/Page91/E4/z8.jpg`,
      //   `img/FriendsPlus/Page91/E4/z8-1.jpg`,
      // ],
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div><img src='img/FriendsPlus/Page91/E4/a.jpg' alt='' style='height:11.5cm'/></div>
            <div style='position: absolute; top: 92px;  left: 53px;'> <select id='0' group='0'  width='400' height='40'></select></div>
            <div style='position: absolute; top: 175px; left: 53px;'><select id='1' group='1'  width='600' height='40'></select></div>
            <div style='position: absolute; top: 259px; left: 53px;'><select id='2' group='2'  width='425' height='40'></select></div>
            <div style='position: absolute; top: 340px; left: 53px;'><select id='3' group='3'  width='487' height='40'></select></div>

            
          </div>
        `,
        answer: [
          `img/FriendsPlus/Page91/E4/z1-1.jpg`,
          `img/FriendsPlus/Page91/E4/z1-1.jpg`,
          `img/FriendsPlus/Page91/E4/z2-1.jpg`,
          `img/FriendsPlus/Page91/E4/z2-1.jpg`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P91-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    styleContent: {
      width: "45%",
      marginLeft: 250,
    },
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page91/E5/1.jpg" }]],
  },
};

export default json;
