import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
const json = {
  8: {
    unit: "Review 4",
    id: "SB5-2024-R4-P97-E8",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page97/Key/E8answerKey.png",
    video: "",
    component: T6,
    // maxLength: 1,
    textAlign: "center",
    inputSize: 150,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid gray",
      // fontSize: 22,
      height: 27,
      // textTransform: "lowercase",
      // borderStyle: "dotted",
      textAlign: "center",
    },
    // hintBox: [
    //   {
    //     src: ["fast", "polite", "hard", "neat", "loud", "<s>good</s>"],
    //     width: 682,
    //   },
    // ],
    titleQuestion: [
      {
        num: "8",
        title:
          "Read the story and look at the pictures. Write one word in each gap.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    // selectStyle: {
    //   height: 35,
    //   width: 35,
    //   textAlign: "center",
    // },
    // selectOptionRandom: true,
    // selectOptionValues: ["T", "F"],
    questions: [
      {
        title: `
        <div style=' margin-left: -20px'>
        <img style='height:8cm; margin-top: 30px' src='img/FriendsPlus/Page97/E8/1.jpg'/>
        <div style='width: 23cm; line-height: 45px'>
          My family love watching &ensp;<sup><b>1</b></sup>#. My dad and I like watching sports.
          Tonight, there is a &ensp;<sup><b>2</b></sup># match between Viet Nam and Thailand at 5 o'clock. 
          Which team will be win the game? My dad and I are so excited. My mom enjoys the show "Are you smarter than a 5&ensp;<sup>th</sup> grader?" at 8 o'clock. 
          Students from Quang Trung Primary &ensp;<sup><b>3</b></sup># are going to be in the show. 
          They are my &ensp;<sup><b>4</b></sup>#. 
          I want to know who will answer all the questions and win a &ensp;<sup><b>5</b></sup>#.

      
        </div>
      </div>
          
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["TV", "soccer|football", "school", "friends", "tablet"],
      },
    ],
  },
  9: {
    unit: "Review 4",
    id: "SB5-2024-R4-P97-E9",
    // audio: "Audios/Page97/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page97/Key/E9answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    // inputSize: 200,
    textareaStyle: {
      // background: "none",
      width: 880,
      // fontSize: 22,
      paddingTop: 5,
      marginLeft: 30,
      // borderBottom: "1.5px solid gray",
      // height: 70,
    },
    titleQuestion: [
      {
        num: "9",
        title:
          "Write an email (30–40 words) to your pen pal about what you’re going to do this summer.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative'>
          <div style='margin-left: -0px'><img style='height:16cm;margin-top:10px' src='img/FriendsPlus/Page97/E9/1.jpg'/><br></div>
          <div style='position: absolute; top: 248px; left: 19px'><textarea id='0' rows='8'></div>
        </div>
        
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [""],
      },
    ],
  },
};

export default json;
