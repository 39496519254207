import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Starter",
    id: "SB5-2024-Starter-P6-E1",
    audio: "Audios/Page6/4.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='04' src='Audios/Page6/4 tieude.mp3'></headphone>",
        color: "#203c8f",
        // numberImg: "c",
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 170,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page6/E1/1.jpg",
          audioUrl: "Audios/Page6/ten.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/2.jpg",
          audioUrl: "Audios/Page6/twenty.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/3.jpg",
          audioUrl: "Audios/Page6/thirty.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/4.jpg",
          audioUrl: "Audios/Page6/forty.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/5.jpg",
          audioUrl: "Audios/Page6/fifty.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E1/6.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E1/7.jpg",
          audioUrl: "Audios/Page6/sixty.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/8.jpg",
          audioUrl: "Audios/Page6/seventy.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/9.jpg",
          audioUrl: "Audios/Page6/eighty.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/10.jpg",
          audioUrl: "Audios/Page6/ninety.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/11.jpg",
          audioUrl: "Audios/Page6/one hundred.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Starter",
    id: "SB5-2024-Starter-P6-E2",
    exerciseKey: "img/FriendsPlus/Page6/Key/E2answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 180,
    // hideBtnFooter: true,
    textAlign: "center",
    textareaStyle: { width: 800, paddingTop: 5 },
    titleQuestion: [
      {
        num: "2",
        title: "Look and write.",
        color: "#203c8f",
        numberImg: ["a"],
        left: 100,
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex;justify-content:space-between;margin-bottom:10px'>
          <div style='margin: 0px 40px'>
            <img style='height:4cm' src='img/FriendsPlus/Page6/E2/1.jpg'/>
            <div>#</div>
          </div>
          <div>
            <img style='height:4cm' src='img/FriendsPlus/Page6/E2/2.jpg'/>
            <div>#</div>
          </div>
          <div style='margin: 0px 40px'>
            <img style='height:4cm' src='img/FriendsPlus/Page6/E2/3.jpg'/>
            <div>#</div>
          </div>
          <div>
            <img style='height:4cm' src='img/FriendsPlus/Page6/E2/4.jpg'/>
            <div>#</div>
          </div>
        </div>
        <div style='display:flex'>
          <div style='margin: 0px 40px'>
            <img style='height:4cm' src='img/FriendsPlus/Page6/E2/5.jpg'/>
            <div>#</div>
          </div>
          <div>
            <img style='height:4cm' src='img/FriendsPlus/Page6/E2/6.jpg'/>
            <div>#</div>
          </div>
          <div style='margin: 0px 40px'>
            <img style='height:4cm' src='img/FriendsPlus/Page6/E2/7.jpg'/>
            <div>#</div>
          </div>
          <div>
            <img style='height:4cm' src='img/FriendsPlus/Page6/E2/8.jpg'/>
            <div>#</div>
          </div>
        </div>
      `,
        answer: [
          "twenty-one|twenty one",
          "sixty-three|sixty three",
          "eighty-nine|eighty nine",
          "thirty-four|thirty four",
          "forty-five|forty five",
          "ninety-six|ninety six",
          "seventy-seven|seventy seven",
          "a hundred",
        ],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "SB5-2024-Starter-P6-E3",
    // audio: "Audios/Page56/81.mp3",
    video: "",
    component: T6,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title: "Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 50,
      },
    ],
    styleContent: {
      width: "60%",
      marginLeft: 130,
    },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='position:relative'>
        <div style='position:absolute;top:259px;left:20px'>
          Where do you live?
        </div>
        <div style='position:absolute;top:259px;left:328px'>
          I live at 21 Nguyen Du Street, Ha Noi.
        </div>
        <img style='height:8.5cm' src='img/FriendsPlus/Page6/E3/1.jpg'/>
      </div>
          
        
        `,
        answer: ["saw", "bought", "wrote"],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB5-2024-Starter-P6-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "4",
        title: "Sing and do.",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 130,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page6/E3/1.jpg",
        },
      ],
    ],
  },
};

export default json;
