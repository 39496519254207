import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P19-E1",
    audio: "Audios/Page19/25.mp3",
    exerciseKey: "img/FriendsPlus/Page19/Key/E1answerKey.png",
    video: "",
    component: T6,
    maxLength: 1,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and check (✓) or cross (✗). <headphone name='25' src='Audios/Page19/25 tieude.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["b"],
        left: 50,
      },
    ],
    selectStyle: {
      color: "#00ADFE",
      width: 45,
      height: 45,
      textAlign: "center",
    },
    select: true,
    selectOptionValues: ["✓", "✗"],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      textAlign: "center",
      width: 35,
    },
    questions: [
      {
        title: `
        <div style='position:relative'>
          <img style='height:120mm' src='img/FriendsPlus/Page19/E1/1.jpg'/><br>
          <div style='position:absolute;top:173px;left:836px'><select id=0></select></div>
          <div style='position:absolute;top:173px;left:1424px'><select id=1></select></div>
          <div style='position:absolute;top:396px;left:544px'><select id=2></select></div>
          <div style='position:absolute;top:396px;left:1137px'><select id=3></select></div>
        </div>
        
      `,
        answer: ["✗", "✓", "✗", "✓"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P19-E2",
    // audio: "Audios/Page19/E2/audio-e2.mp3",
    video: "",
    recorder: true,
    styleHint: { justifyContent: "center" },
    hintBox: [
      {
        src: [
          "watching movies / go to the movie theater",
          "listening to music / go to concerts",
          "swimming / go to the swimming pool",
          "reading /  go to the library",
          "shoping / go to the shopping mall",
          "eating out / go to a café",
        ],
        width: 990,
      },
    ],
    stylesTextInput: { textAlign: "center", width: 50 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Ask and answer about you.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
       <hintbox id=0></hintbox>
        <div>
        <img style='height:4cm' src='img/FriendsPlus/Page19/E2/1.jpg'/>
        </div>
      `,
        answer: ["F", "T", "F", "F", "T", "T"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P19-E3",
    audio: "",
    recorder: true,
    hintBox: [
      {
        src: [
          "get up",
          "brush my teeth",
          "start school",
          "finish school",
          "do homework",
          "take a shower",
        ],
        width: 1119,
      },
    ],
    stylesTextInput: { textAlign: "center", width: 50 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title:
          "Say what you and your family <i>always, usually, often, sometimes,</i> and <i>never</i> do.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
          <div>
        <img style='height:17mm' src='img/FriendsPlus/Page19/E3/1.jpg'/>
        </div>
      `,
        answer: ["F", "T", "F", "F", "T", "T"],
      },
    ],
  },
  // 4: {
  //   unit: "Unit 2",
  //   id: "SB5-2024-U2-P19-E4",
  //   audio: "Audios/Page19/E4/audio-e4.mp3",
  //   video: "",
  //   exerciseKey: "img/FriendsPlus/Page19/E4/Key/answerKey.png",
  //   component: DesignUnderLine,
  //   // recorder: true,
  //   question: [],

  //   totalInput: 7,
  //   titleQuestion: [
  //     {
  //       num: "4",
  //       title:
  //         "Listen and underline the letters with schwa. <headphone name='16' src='Audios/Page19/E4/tieude-e4.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
  //       color: "#203c8f",
  //     },
  //   ],
  //   questionImage: [
  //     [{ url: "img/FriendsPlus/Page19/E4/1.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page19/E4/2.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/3.jpg", input: 1 },
  //       { url: "img/FriendsPlus/Page19/E4/4.jpg", input: 1, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E4/5.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/6.jpg", input: 2 },
  //       { url: "img/FriendsPlus/Page19/E4/7.jpg", input: 2, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E4/8.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page19/E4/9.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page19/E4/10.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/11.jpg", input: 3 },
  //       { url: "img/FriendsPlus/Page19/E4/12.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/13.jpg", input: 3, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E4/14.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/15.jpg", input: 4 },
  //       { url: "img/FriendsPlus/Page19/E4/16.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/17.jpg", input: 4, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E4/18.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page19/E4/19.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page19/E4/20.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/21.jpg", input: 5 },
  //       { url: "img/FriendsPlus/Page19/E4/22.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/23.jpg", input: 5 },
  //       { url: "img/FriendsPlus/Page19/E4/24.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/25.jpg", input: 5 },
  //       { url: "img/FriendsPlus/Page19/E4/26.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/27.jpg", input: 5, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E4/28.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/29.jpg", input: 6, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E4/30.jpg", input: 6 },
  //       { url: "img/FriendsPlus/Page19/E4/31.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/32.jpg", input: 7 },
  //       { url: "img/FriendsPlus/Page19/E4/33.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/34.jpg", input: 7, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E4/35.jpg" },
  //       { url: "img/FriendsPlus/Page19/E4/36.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page19/E4/37.jpg" }],
  //   ],
  // },
  4: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P19-E5",
    hintBox: [
      {
        src: [
          "get up",
          "brush my teeth",
          "start school",
          "finish school",
          "do homework",
          "take a shower",
        ],
        width: 1119,
      },
    ],
    textareaStyle: {
      // background: "#edf1fa",
      paddingTop: 3,
      resize: "none",
      width: 916,
    },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write a review of your favorite movie. Use the example to help you",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:6cm' src='img/FriendsPlus/Page19/E4/2.jpg'/>
        <div style='position:relative'>
          <img style='height:8cm' src='img/FriendsPlus/Page19/E4/1.jpg'/>
          <div style='position:absolute;top:75px;left:16px'><textarea id=0 rows=5></textarea></div>
        </div>
      `,
        answer: ["F", "T", "F", "F", "T", "T"],
      },
    ],
  },
};

export default json;
