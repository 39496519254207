import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P58-E1",
    audio: "Audios/Page58/84.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='84' src='Audios/Page58/84 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page58/E1/1.jpg",
          audioUrl: "Audios/Page58/84 scientist.mp3",
        },
        { url: "img/FriendsPlus/Page58/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page58/E1/3.jpg",
          audioUrl: "Audios/Page58/84 tourist.mp3",
        },
        { url: "img/FriendsPlus/Page58/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page58/E1/5.jpg",
          audioUrl: "Audios/Page58/84 jungle.mp3",
        },
        { url: "img/FriendsPlus/Page58/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page58/E1/7.jpg",
          audioUrl: "Audios/Page58/84 cave.mp3",
        },
        { url: "img/FriendsPlus/Page58/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page58/E1/9.jpg",
          audioUrl: "Audios/Page58/84 river.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P58-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page58/E2/Key/answerKey.png",
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "2",
        title: "Look at the text. What can you see in the photos?",
        color: "#203c8f",
        left: 50,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
				<img style='height:12cm' src='img/FriendsPlus/Page58/E3/1.jpg'/>
        <div>
        What can you see in the photos?<br>#
                  </div>
        
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P58-E3",
    audio: "Audios/Page58/85.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='85' src='Audios/Page58/85 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page58/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P58-E4",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page58/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: "Read again and circle the correct answer. ",
        color: "#203c8f",
        numberImg: ["b"],
        left: 0,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.| ",
          "a.| <br> b.| <br> c.|",
        ],
        answers: ["0-6", "1-12", "4-0", "2-6", "3-0"],
        initialValue: [],
      },
      Layout: `
      <img style='height:10cm' src='img/FriendsPlus/Page58/E3/1.jpg'/>
     <div style='display:flex'>
      <div style='margin-right:50px;'>
       <div><b>1.</b> Mr. Khanh was____________.
        <div style='display:flex'>
          <div><input id='0' type='Circle'/></div>
          <div>a scientist <br> a farmer <br> a tourist</div>
        </div>
       </div>
       <div style='width:10cm'><b>3.</b> When Mr. Khanh found the cave he was____________.
        <div style='display:flex'>
          <div><input id='4' type='Circle'/></div>
          <div>suprised <br> sad <br> scared</div>
        </div>
       </div>
      </div> 
       
      <div> 
       <div style='width:10cm'><b>2.</b> Mr. Khanh first found the cave in____________.
        <div style='display:flex'>
          <div><input id='1' type='Circle'/></div>
          <div>2023 <br> 2009 <br> 1991</div>
        </div>
       </div>
       <div><b>4.</b> The cave is very____________.
        <div style='display:flex'>
          <div><input id='2' type='Circle'/></div>
          <div>old <br> big <br> small</div>
        </div>
       </div>
      </div> 
    </div> 
       <div><b>5.</b> Inside the cave there is____________.
        <div style='display:flex'>
          <div><input id='3' type='Circle'/></div>
          <div>a jungle <br> a farm <br> a museum</div>
        </div>
       </div>
      `,
    },
  },
  5: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P58-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "5",
        title: "Would you like to visit a cave? Why / Why not?",
        color: "#203c8f",
        left: 0,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
