import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  3: {
    unit: "Review 1",
    id: "SB5-2024-R1-P27-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/Key/E3answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: "Circle the odd one out.",
        color: "#203c8f",
        width: "24cm",
        left: -50,
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "46px",
              left: "250px",
              width: "173px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "46px",
              left: "846px",
              width: "218px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "238px",
              left: "120px",
              width: "121px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "238px",
              left: "922px",
              width: "176px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "429px",
              left: "234px",
              width: "165px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "429px",
              left: "841px",
              width: "175px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "367px",
              left: "534px",
              width: "188px",
              height: "234px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "116px",
              left: "534px",
              width: "170px",
              height: "234px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          // border: "2px solid",
          border: "2px solid transparent",
          // borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          // border: "none",
          border: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "walks <br> sits <br> rides",
          "loves <br> watches <br> brushes",
          "studies <br> goes <br> writes",
          "sandwiches <br> cookies <br> boxes",
          "reads <br> catches <br> runs",
          "listens <br> eats <br> gets",
        ],
        answers: ["0-8", "1-0", "2-8", "3-4", "4-4", "5-0"],
        initialValue: ["0-8"],
      },
      Layout: `
      <div style='display:flex'>
        <div style='margin-right:200px'>
            <div style='display:flex'>
                <b>1.</b><br>
              <div style='margin-left:10px'>
                <input id=0 type='Circle'/>
              </div>
            </div>
            <div style='display:flex;margin:20px 0px'>
                <b>2.</b><br>
              <div style='margin-left:10px'>
                <input id=1 type='Circle'/>
              </div>
            </div>
            <div style='display:flex'>
                <b>3.</b><br>
              <div style='margin-left:10px'>
                <input id=2 type='Circle'/>
              </div>
            </div>
        </div>
        <div>
            <div style='display:flex'>
                <b>4.</b><br>
              <div style='margin-left:10px'>
                <input id=3 type='Circle'/>
              </div>
            </div>
            <div style='display:flex;margin:20px 0px'>
                <b>5.</b><br>
              <div style='margin-left:10px'>
                <input id=4 type='Circle'/>
              </div>
            </div>
            <div style='display:flex'>
                <b>6.</b><br>
              <div style='margin-left:10px'>
                <input id=5 type='Circle'/>
              </div>
            </div>
        </div>
      </div>
        
      
      `,
    },
  },
  4: {
    unit: "Review 1",
    id: "SB5-2024-R1-P27-E4",
    exerciseKey: "img/FriendsPlus/Page27/Key/E4answerKey.png",
    video: "",
    recorder: true,
    hintBox: [
      {
        src: [
          "get up",
          "brush my teeth",
          "start school",
          "finish school",
          "do homework",
          "take a shower",
        ],
        width: 1119,
      },
    ],
    stylesTextInput: { textAlign: "center", width: 200 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Describe your routines.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:15cm' src='img/FriendsPlus/Page27/E4/1.jpg'/>
        
      `,
        answer: [
          "on Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious",
        ],
      },
    ],
  },
  5: {
    unit: "Review 1",
    id: "SB5-2024-R1-P27-E5",
    exerciseKey: "img/FriendsPlus/Page27/Key/E5answerKey.png",
    video: "",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
    },
    hintBox: [
      {
        src: [
          "In the evening, always eat dinner dinner with my family. My mom get home at ...",
        ],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
    ],
    textareaStyle: {
      textAlign: "center",
      paddingTop: 3,
      resize: "none",
      width: 900,
    },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "5",
        title:
          "What do you and your family usually do in the evening? Write a paragraph of 30-40 words.",
        color: "#203c8f",
        width: 1000,
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div>
          <textarea id=0 rows=5></textareai>
        </div>
      `,
        answer: [
          "on Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious",
        ],
      },
    ],
  },
};

export default json;
