import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Fluency Time! 4",
    id: "SB5-2024-FT4-P99-E1",
    audio: "Audios/Page99/147.mp3",
    // video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 08.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "85%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='147' src='Audios/Page99/147 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page99/E1/1.jpg",
          audioUrl: "Audios/Page99/robot arm.mp3",
        },
        {
          url: "img/FriendsPlus/Page99/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page99/E1/3.jpg",
          audioUrl: "Audios/Page99/operation.mp3",
        },
        {
          url: "img/FriendsPlus/Page99/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page99/E1/5.jpg",
          audioUrl: "Audios/Page99/factory.mp3",
        },
        {
          url: "img/FriendsPlus/Page99/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page99/E1/7.jpg",
          audioUrl: "Audios/Page99/human.mp3",
        },
        {
          url: "img/FriendsPlus/Page99/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page99/E1/9.jpg",
          audioUrl: "Audios/Page99/japan.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 4",
    id: "SB5-2024-FT4-P99-E2",
    audio: "Audios/Page99/148.mp3",
    // video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 08.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 30,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='148' src='Audios/Page99/148 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page99/E2/1.jpg",
          // audioUrl: "Audios/Page99/hinh1.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Fluency Time! 4",
    id: "SB5-2024-FT4-P99-E3",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page99/Key/E3answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 900,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 5,
      height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "3",
        title: "Answer the questions.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:15cm; margin-left: -30px' src='img/FriendsPlus/Page99/E2/1.jpg'/>
        <div style='display; flex'>
        <div style='line-height: 40px; font-size: 22px'>
          <div style='display: flex'><b>1.</b>&ensp;What is special about ASIMO?</div> #<br>
          <div style='display: flex'><b>2.</b>&ensp;What will every factory use in the future?</div>#<br>
          <div style='display: flex'><b>3.</b>&ensp;Will humans do dangerous jobs?</div>#<br>
          <div style='display: flex'><b>4.</b>&ensp;How can robots help doctors?</div>#<br>
          
        </div>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "It can walk and run like a human. It can understand human language, too.",
          "Every factory will use robots.|They will use robots.",
          "No, they won't.|No, they will not.",
          "They could help with operations in hospitals.|They can help with operations in hospitals.",
        ],
      },
    ],
  },
  4: {
    unit: "Fluency Time! 4",
    id: "SB5-2024-FT4-P99-E4",
    // audio: "Audios/Page99/audio-e2.mp3",
    // video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 08.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "90%",
      marginLeft: 30,
    },
    titleQuestion: [
      {
        num: "4",
        title:
          "<div style='display: flex'><img style='height:1.2cm' src='img/FriendsPlus/Page75/E4/2.jpg'/> <div>What things would you like robots to do in the future? Design a robot. What can it do? Tell your friend.</div></div>",
        color: "#203c8f",
        numberImg: ["a", "c"],
      },
    ],
    questionImage: [
      [
        // {
        //   url: "img/FriendsPlus/Page99/E4/1.jpg",
        //   // audioUrl: "Audios/Page99/hinh1.mp3",
        // },
      ],
    ],
  },
  5: {
    unit: "Fluency Time! 4",
    id: "SB5-2024-FT4-P99-E5",
    // audio: "Audios/Page29/E6/audio-e4.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page99/E5/Key/E5answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "5",
        title: "Look and color.",
        color: "#203c8f",
        // numberImg: ["a", "c"],
      },
    ],
    hideBtnFooter: true,
    question: {
      ThSmile: true,
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "8px 18px",
          border: "2px solid",
          color: "transparent",
          marginLeft: 10,
          // background: "gray",
          borderRadius: "50%",
          borderColor: "gray",
          fontWeight: "bold",
          backgroundColor: "white",
        },
        selectWordStyle: {
          fontWeight: "bold",
          width: 10,
          borderRadius: "50%",
          background: "#959595",
          color: "transparent",
          border: "2px solid",
          borderColor: "#959595",
          // backgroundColor: "white",
        },
        limitSelect: 3,
        listWords: ["a", "a a", "a a a"],
        answers: [""],
        initialValue: [],
      },
      Layout: `
      <div style='border:3px solid rgb(56,73,137);padding:10px 20px;box-shadow:4px 2px 3px gray;border-radius:14px'>
        <span style='color:rgb(56,73,137); font-weight:bold;font-size:30px;margin-left:10px'>
          My work in Units 10-12 & Fluency Time! 4 is
        </span>
          <div style='display:flex;margin-top:30px'>
            <div>
              <input id=0 select='Tick' type='Circle'/> 
              <span style='color:rgb(56,73,137); font-weight:bold;font-size:30px;margin-left:10px'>OK</span>
            </div>
            <div style='margin:0px 100px'>
              <input id=1 select='Tick' type='Circle'/> 
              <span style='color:rgb(56,73,137); font-weight:bold;font-size:30px;margin-left:10px'>GOOD</span>
            </div>
            <div>
              <input id=2 select='Tick' type='Circle'/> 
              <span style='color:rgb(56,73,137); font-weight:bold;font-size:30px;margin-left:10px'>EXCELLENT</span>
            </div>
          </div>
      </div>
        
      `,
    },
  },
};

export default json;
