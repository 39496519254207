import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P87-E1",
    audio: "Audios/Page87/132.mp3",
    exerciseKey: "img/FriendsPlus/Page87/Key/E1answerKey.png",
    video: "",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    // inputSize: 200,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid gray",
      // fontSize: 22,
      paddingBottom: 3,
      height: 40,
      width: 35,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write the numbers. <headphone name='132' src='Audios/Page87/132 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative'>
          <img style='height:9.5cm; margin-top:10px; margin-left: -80px' src='img/FriendsPlus/Page87/E1/1.jpg'/>
          <div style='position: absolute; top: 133px; left: 152px'>#</div>
          <div style='position: absolute; top: 133px; left: 692px'>#</div>
          <div style='position: absolute; top: 302px; left: 152px'>#</div>
          
        </div>
        
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["2", "4", "3"],
      },
    ],
  },

  2: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P87-E2",
    // audio: "Audios/Page64/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 100,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "What do you think life will be like in 100 years? Ask and answer about you.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page87/E2/1.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
    ],
  },

  3: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P87-E4",
    // audio: "Audios/Page87/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page87/Key/E4answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    // inputSize: 200,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 70,
    },
    titleQuestion: [
      {
        num: "3",
        title: "Write an opinion paragraph. Use the example to help you.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <div style='margin-left: -0px'><img style='height:9cm;margin-top:10px' src='img/FriendsPlus/Page87/E3/1.jpg'/><br></div>
          <textarea id='0' rows='8'>
        </div>
        
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [""],
      },
    ],
  },
};

export default json;
