import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P44-E1",
    // audio: "Audios/Page59/Track 086.mp3",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page44/Key/E1answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["It wasn't rainy on vacation. We didn't go..."],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      textAlign: "center",
      width: 100,
    },
    textareaStyle: { paddingTop: 1, width: 750 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title:
          "Copy the table. Look and say. Write <i>Y</i>(yes) or <i>N</i>(no).",
        color: "#203c8f",
        numberImg: ["c", "a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <div style='position:relative'>
          <img style='height:14cm' src='img/FriendsPlus/Page44/E1/1.jpg'/>
          <div style='position:absolute;top:288px;left:171px'>#</div>
          <div style='position:absolute;top:288px;left:322px'>#</div>
          <div style='position:absolute;top:288px;left:473px'>#</div>
          <div style='position:absolute;top:288px;left:624px'>#</div>
          <div style='position:absolute;top:288px;left:775px'>#</div>
          <div style='position:absolute;top:359px;left:171px'>#</div>
          <div style='position:absolute;top:359px;left:322px'>#</div>
          <div style='position:absolute;top:359px;left:473px'>#</div>
          <div style='position:absolute;top:359px;left:624px'>#</div>
          <div style='position:absolute;top:359px;left:775px'>#</div>
        </div>
            
      
      `,
        answer: [
          "Does Linh have a snack after school?",
          "Tuan and Mai don't catch the bus.|Mai and Tuan don't catch the bus.",
          "Do they brush their teeth at night?",
          "He doesn't walk to school.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P44-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page9/Key/E3answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["I watched TV yesterday. I didn't play soccer yesterday. I..."],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    textareaStyle: { paddingTop: 1, width: 750 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Write four sentences about you.",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
            <hintbox id=0></hintbox>
          <textarea id=0 rows=5></textarea>
      
      `,
        answer: [
          "Does Linh have a snack after school?",
          "Tuan and Mai don't catch the bus.|Mai and Tuan don't catch the bus.",
          "Do they brush their teeth at night?",
          "He doesn't walk to school.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P44-E3",
    audio: "Audios/Page44/65.mp3",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page9/Key/E3answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { width: 550 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and sing. <headphone name='65' src='Audios/Page44/65 tieude.mp3'></headphone>",
        color: "#203c8f",
        // numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:13cm' src='img/FriendsPlus/Page44/E3/1.jpg'/>
        
      `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P44-E4",
    audio: "",
    video:
      "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/My birthday party.mp4",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page9/Key/E4answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 170 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Sing and do. ",
        color: "#203c8f",
        // numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:13cm' src='img/FriendsPlus/Page44/E3/1.jpg'/>
        
      `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P44-E5",
    audio: "",
    video: "",

    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 778,
      paddingTop: 5,
    },
    inputSize: 720,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title: "Write a tourist leaflet. Use the example to help you.",
    //     color: "#203c8f",
    //     numberImg: "a",
    //     left: 80,
    //   },
    // ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img style='height:13cm' src='img/FriendsPlus/Page9/E5/1.jpg'/>
				  
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
