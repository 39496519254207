import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P21-E1",
    audio: "Audios/Page20/28.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 3.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='28' src='Audios/Page20/28.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page20/E3/1.jpg",
          audioUrl: "Audios/Page20/28 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E3/2.jpg",
          audioUrl: "Audios/Page20/28 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page20/E3/3.jpg",
          audioUrl: "Audios/Page20/28 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E3/4.jpg",
          audioUrl: "Audios/Page20/28 04.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P21-E2",
    audio: "Audios/Page21/29.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='29' src='Audios/Page21/29 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page21/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page21/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page21/E2/3.jpg",
          audioUrl: "Audios/Page21/29 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page21/E2/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page21/E2/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page21/E2/6.jpg",
          audioUrl: "Audios/Page21/29 02.mp3",
        },
        {
          url: "img/FriendsPlus/Page21/E2/7.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page21/E2/8.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P21-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page21/Key/E3answerKey.png",

    // maxLength: 1,
    hintBox: [
      {
        src: ["Could", "<s>one</s>", "some", "three", "Could"],
      },
      {
        src: ["one"],
        styleElement: { borderBottom: "1px solid", color: "#10adcf" },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 100 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Read and Write T(true) or F(false).",
        color: "#203c8f",
        numberImg: ["b"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='margin-top:20px;line-height:48px'>
          <b>1</b>&ensp;Could I have <u style='color:rgb(16,173,207) '>&ensp;one&ensp;</u> onion, please?<br>
          <b>2</b>&ensp;# you help me, please?<br>
          <b>3</b>&ensp;Could we have # ice tea, please?<br>
          <b>4</b>&ensp;# I have # bananas, please?<br>
        
        </div>
        
        
      `,
        // initialValue: ["T", "", "", ""],
        answer: ["Could", "some", "Could", "some"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P21-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page21/Key/E4answerKey.png",
    // styleHint: {
    //   border: "none",
    //   paddingInline: 3,
    //   paddingBlock: "0px",
    //   styleElementHintBox: {
    //     background: "#fee7d2",
    //     marginRight: 20,
    //     padding: "2px 5px",
    //     borderRadius: "10px",
    //   },
    // },
    hintBox: [
      {
        src: ["on", "in", "at"],
        width: 216,
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 300 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Write",
        color: "#203c8f",
        numberImg: ["b"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex'>
          <div>
            <div>
              <img style='height:4cm' src='img/FriendsPlus/Page21/E4/1.jpg'/><br>
              Could I have #?
            </div>
            <div>
              <img style='height:4cm' src='img/FriendsPlus/Page21/E4/2.jpg'/><br>
              Could we have #?
            </div>
          </div>
          <div style='margin-left:50px'>
            <div>
              <img style='height:4cm' src='img/FriendsPlus/Page21/E4/3.jpg'/><br>
              Could we #?
            </div>
            <div>
              <img style='height:4cm' src='img/FriendsPlus/Page21/E4/4.jpg'/><br>
              Could #?
            </div>
          </div>
        </div>
        
      `,
        answer: [
          "a sandwich, please|one sandwich, please",
          "some pizza, please|some pizza, please",
          "have some juice, please|have some juice, please",
          "I have an apple, please|I have one apple, please",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P21-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 778,
      paddingTop: 5,
    },
    inputSize: 720,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title: "Write a tourist leaflet. Use the example to help you.",
    //     color: "#203c8f",
    //     numberImg: "a",
    //     left: 80,
    //   },
    // ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img style='height:10cm' src='img/FriendsPlus/Page21/E5/1.jpg'/>
				  
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
