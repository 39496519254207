import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P85-E1",
    audio: "Audios/Page85/128.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 30,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='128' src='Audios/Page85/128 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page85/E1/1.jpg",
          audioUrl: "Audios/Page85/eat an orange.mp3",
        },
        {
          url: "img/FriendsPlus/Page85/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page85/E1/3.jpg",
          audioUrl: "Audios/Page85/peel an apple.mp3",
        },
        {
          url: "img/FriendsPlus/Page85/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page85/E1/5.jpg",
          audioUrl: "Audios/Page85/take an umbrella.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P85-E2",
    audio: "Audios/Page85/129.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 30,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='129' src='Audios/Page85/129 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page85/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P85-E3",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page85/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Draw lines to show the words that joins together as you say them.",
        color: "#203c8f",
        width: "26cm",
        numberImg: ["c", "b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        dontChangeColor: true,
        initialWordStyle: {
          padding: "1px 1px",
          border: "2px solid",
          borderColor: "none",
          borderRadius: "40%",
          fontWeight: "bold",
          color: "transparent",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 1px",
          // borderRadius: "50%",
          color: "transparent",
          border: "none",
          borderTop: "3px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "w &ensp; ww",
          "ww ww",
          "ww ww",
          "wi ww &ensp; ww",

          "w &ensp; ww",
          "ww ww",
          "ww ww",
          "wi ww &ensp; ww",

          "wi &ensp; wi &ensp; wi",
          "ww  ww",
          "ww  ww",
          "wi ww &ensp; ww",
        ],
        answers: [
          "1-0",
          "1-2",
          "2-0",
          "2-2",
          "5-0",
          "5-2",
          "6-0",
          "6-2",
          "9-0",
          "9-4",
          "10-0",
          "10-4",
        ],
        initialValue: ["1-0", "1-2"],
      },
      Layout: `
      <div style='position: relative; margin-left: 100px'>
      <img style='height:15cm;margin-top:20px; margin-left: -50px' src='img/FriendsPlus/Page85/E3/1.jpg'/>
      
      <div style='position: absolute; top:16px; left: 305px; line-height: 41.7px'>

        <div>
          <div style='position: absolute; top: -2px; left: 6px'><input id=0 type='Circle' /></div>
          <div>
            I<span style='color: white'>w</span>feel<span style='color: white'>w</span>thirsty.
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 41px; left: 23px'><input id=1 type='Circle' /></div>
          <div>
          Eat<span style='color: white'>w</span>an<span style='color: white'>w</span>orange.
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 82px; left: 23px'><input id=2 type='Circle' /></div>
          <div>
          Eat<span style='color: white'>w</span>an<span style='color: white'>w</span>orange.
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 125px; left: 61px'><input id=3 type='Circle' /></div>
          <div>
          That's<span style='color: white'>w</span>a<span style='color: white'>w</span>good<span style='color: white'>w</span>idea!
          </div>
        </div><br>

        <div>
          <div style='position: absolute; top: 207px; left: 6px'><input id=4 type='Circle' /></div>
          <div>
          I<span style='color: white'>w</span>feel<span style='color: white'>w</span>hungry.
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 250px; left: 39px'><input id=5 type='Circle' /></div>
          <div>
          Peel<span style='color: white'>w</span>an<span style='color: white'>w</span>apple.
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 291px; left: 35px'><input id=6 type='Circle' /></div>
          <div>
          Peel<span style='color: white'>w</span>an<span style='color: white'>w</span>apple.
          </div>
        </div>
        <div>
        <div style='position: absolute; top: 332px; left: 61px'><input id=7 type='Circle' /></div>
          <div>
          That's<span style='color: white'>w</span>a<span style='color: white'>w</span>good<span style='color: white'>w</span>idea!
          </div>
        </div><br>

        <div>
          <div style='position: absolute; top: 414px; left: 28px'><input id=8 type='Circle' /></div>
          <div>
          Oh<span style='color: white'>w</span>no!<span style='color: white'>w</span>It's<span style='color: white'>w</span>rainy!
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 458px; left: 40px'><input id=9 type='Circle' /></div>
          <div>
          Take<span style='color: white'>w</span>an<span style='color: white'>w</span>umbrella.
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 500px; left: 40px'><input id=10 type='Circle' /></div>
          <div>
          Take<span style='color: white'>w</span>an<span style='color: white'>w</span>umbrella.
          </div>
        </div>
        <div>
        <div style='position: absolute; top: 540px; left: 61px'><input id=11 type='Circle' /></div>
          <div>
          That's<span style='color: white'>w</span>a<span style='color: white'>w</span>good<span style='color: white'>w</span>idea!
          </div>
        </div>

      </div>
        </div>
      </div>
      </div>
      `,
    },
  },
  4: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P85-E4",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page85/Key/E4answerKey.png",
    component: Circle_Write,
    // recorder: true,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "4",
        title:
          "Say the sentences. Draw lines to show the words that join together.",
        color: "#203c8f",
        width: "24cm",
        numberImg: ["c", "b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        dontChangeColor: true,
        initialWordStyle: {
          padding: "1px 1px",
          border: "3px solid",
          borderColor: "none",
          borderRadius: "37%",
          fontWeight: "bold",
          color: "transparent",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 1px",
          // borderRadius: "50%",
          color: "transparent",
          border: "none",
          borderTop: "3px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 5,
        listWords: [
          "w ww ww",
          "w ww ww",
          "w ww",
          "w ww &ensp; wi wii &ensp; w",
          "w &ensp; ww &ensp; ww",
        ],
        answers: ["0-0", "3-0", "1-0", "2-0", "3-6", "4-0"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='position: relative; margin-left: 100px; line-height: 50px'>
        <div>
          <div style='position: absolute; top: 0px; left: 74px'><input id=0 type='Circle' /></div>
          <div>
            <b>1.&ensp;</b>Get<span style='color: white'>w</span>in<span style='color: white'>w</span>the<span style='color: white'>w</span>rocket.
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 48px; left: 91px'><input id=1 type='Circle' /></div>
          <div>
            <b>2.&ensp;</b>Look<span style='color: white'>w</span>at<span style='color: white'>w</span>the<span style='color: white'>w</span>stars.
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 97px; left: 89px'><input id=2 type='Circle' /></div>
          <div>
            <b>3.&ensp;</b>Walk<span style='color: white'>w</span>in<span style='color: white'>w</span>space.
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 151px; left: 89px'><input id=3 type='Circle' /></div>
          <div>
            <b>4.&ensp;</b>Take<span style='color: white'>w</span>a<span style='color: white'>w</span>tour<span style='color: white'>w</span>of<span style='color: white'>w</span>the<span style='color: white'>w</span>moon.
          </div>
        </div>
        <div>
          <div style='position: absolute; top: 197px; left: 91px'><input id=4 type='Circle' /></div>
          <div>
            <b>5.&ensp;</b>We'll<span style='color: white'>w</span>eat<span style='color: white'>w</span>space<span style='color: white'>w</span>food.
          </div>
        </div>
      </div>
      
      `,
    },
  },
  5: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P85-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    styleContent: {
      width: "45%",
      marginLeft: 250,
    },
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page85/E5/1.jpg" }]],
  },
};

export default json;
