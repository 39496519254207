import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P31-E1",
    audio: "Audios/Page30/43.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/unit 4.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='43' src='Audios/Page30/43 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page30/E3/1.jpg",
          audioUrl: "Audios/Page30/43 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E3/2.jpg",
          audioUrl: "Audios/Page30/43 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page30/E3/3.jpg",
          audioUrl: "Audios/Page30/43 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E3/4.jpg",
          audioUrl: "Audios/Page30/43 04.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P31-E2",
    audio: "Audios/Page31/44.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='44' src='Audios/Page31/44 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page31/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page31/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E2/3.jpg",
          audioUrl: "Audios/Page31/44.mp3",
        },
        {
          url: "img/FriendsPlus/Page31/E2/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page31/E2/5.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P31-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page31/Key/E3answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    // maxLength: 1,
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 100 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Read and Write <i>was</i> or <i>were</i>.",
        color: "#203c8f",
        numberImg: ["b"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <div style='line-height:47px'>
          <b>1</b>&ensp;There#a park in our town 100 years ago.<br>
          <b>2</b>&ensp;There#trains 100 year ago.<br>
          <b>3</b>&ensp;There#a supermarket in the town 10 years ago.<br>
          <b>4</b>&ensp;There#lots of buses 20 years ago.<br>
        </div>
        
      `,
        // initialValue: ["T", "", "", ""],
        answer: ["was", "were", "was", "were"],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P31-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page31/Key/E4answerKey.png",
    // styleHint: {
    //   border: "none",
    //   paddingInline: 3,
    //   paddingBlock: "0px",
    //   styleElementHintBox: {
    //     background: "#fee7d2",
    //     marginRight: 20,
    //     padding: "2px 5px",
    //     borderRadius: "10px",
    //   },
    // },
    hintBox: [
      {
        src: ["on", "in", "at"],
        width: 216,
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 170 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Look and write about a party last Saturday.",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:7cm' src='img/FriendsPlus/Page31/E4/1.jpg'/>
              <div style='margin-top:10px'>
                <b>1</b>&ensp;There#a singer at the party in Saturday.<br>
                <b>2</b>&ensp;There#some children at the party.<br>
                <b>3</b>&ensp;There#any flowers in the room.<br>
                <b>4</b>&ensp;There#lots of sanwiches at the party.<br>
                <b>5</b>&ensp;There#some balloons in the room.<br>
                <b>6</b>&ensp;There#a clown in the room.<br>
              </div>
        
      `,
        answer: [
          "wasn't|was not",
          "were",
          "weren't|were not",
          "were",
          "were",
          "was",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P31-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 778,
      paddingTop: 5,
    },
    inputSize: 720,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title: "Write a tourist leaflet. Use the example to help you.",
    //     color: "#203c8f",
    //     numberImg: "a",
    //     left: 80,
    //   },
    // ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img style='height:14cm' src='img/FriendsPlus/Page31/E5/1.jpg'/>
				  
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
