import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P9-E1",
    audio: "Audios/Page8/7.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 1.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='07' src='Audios/Page8/7 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page8/E3/1.jpg",
          audioUrl: "Audios/Page8/7 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E3/2.jpg",
          audioUrl: "Audios/Page8/7 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page8/E3/3.jpg",
          audioUrl: "Audios/Page8/7 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E3/4.jpg",
          audioUrl: "Audios/Page8/7 04.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P9-E2",
    audio: "Audios/Page9/8.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='08' src='Audios/Page9/8 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page9/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/3.jpg",
          audioUrl: "Audios/Page9/8 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E2/4.jpg",
          audioUrl: "Audios/Page9/8 02.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E2/5.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/7.jpg",
          audioUrl: "Audios/Page9/8 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E2/8.jpg",
          audioUrl: "Audios/Page9/8 04.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E2/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/11.jpg",
          audioUrl: "Audios/Page9/8 05.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E2/12.jpg",
          audioUrl: "Audios/Page9/8 06.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E2/13.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/14.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P9-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page9/Key/E3answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "have", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { width: 550 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Write the words in the correct order.",
        color: "#203c8f",
        numberImg: ["a"],
        left: -30,
      },
    ],
    questions: [
      {
        title: `
              <div style='display:flex;margin-top:20px'>
                <b>1</b>. <hintbox id=0></hintbox>
              </div>
                <hintbox id=1></hintbox>
              <div style='display:flex;margin-top:20px'>
                <b>2</b>. <hintbox id=2></hintbox>
              </div>#
              <div style='display:flex;margin-top:20px'>
                <b>3</b>. <hintbox id=3></hintbox>
              </div>#
              <div style='display:flex;margin-top:20px'>
                <b>4</b>. <hintbox id=4></hintbox>
              </div>#
              <div style='display:flex;margin-top:20px'>
                <b>5</b>. <hintbox id=5></hintbox>
              </div>#
        
      `,
        answer: [
          "Does Linh have a snack after school?",
          "Tuan and Mai don't catch the bus.|Mai and Tuan don't catch the bus.",
          "Do they brush their teeth at night?",
          "He doesn't walk to school.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P9-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page9/Key/E4answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 170 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Write",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
              <div style='display:flex'>
                <div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page9/E4/1.jpg'/><br>
                    Minh#(get dressed) at <br>six o'clock.
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page9/E4/2.jpg'/><br>
                    Mi#(brush) her teeth <br>every morning.
                  </div>
                </div>
                <div style='margin-left:50px'>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page9/E4/3.jpg'/><br>
                    Ly and Vy#(not catch) the <br> bus to school.
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page9/E4/4.jpg'/><br>
                    Lam#(have) a snack after <br> school.
                  </div>
                </div>
              </div>
        
      `,
        answer: ["gets dressed", "brushes", "don't catch", "has"],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P9-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 778,
      paddingTop: 5,
    },
    inputSize: 720,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title: "Write a tourist leaflet. Use the example to help you.",
    //     color: "#203c8f",
    //     numberImg: "a",
    //     left: 80,
    //   },
    // ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img style='height:13cm' src='img/FriendsPlus/Page9/E5/1.jpg'/>
				  
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
