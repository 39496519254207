import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Fluency Time! 1",
    id: "SB5-2024-FL1-P6-E1",
    audio: "Audios/Page28/37.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen. Read and say. <headphone name='37' src='Audios/Page28/37 tieude.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["c"],
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 170,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page28/E1/1.jpg",
          audioUrl: "Audios/Page28/37 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page28/E1/2.jpg",
          audioUrl: "Audios/Page28/37 02.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 1",
    id: "SB5-2024-FL1-P6-E2",
    exerciseKey: "img/FriendsPlus/Page28/Key/E2answerKey.png",
    audio: "Audios/Page28/38.mp3",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and circle the answer. <headphone name='38' src='Audios/Page28/38 tieude.mp3'></headphone>",
        color: "#203c8f",
        width: "24cm",
        left: -50,
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "46px",
              left: "250px",
              width: "173px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          // border: "2px solid",
          border: "2px solid white",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          // border: "none",
          border: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "chicken / a_pizza",
          "chickend_and_mushrooms / tomatoes_and_onions",
          "lemonade / soda",
          "delicious / healthy",
        ],
        answers: ["0-4", "1-0", "2-4", "3-4"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
        <div style='width:17cm;line-height:48px'>
          <b>1.</b>&ensp;Sam wants <input id=0 type='Circle'/>.<br>
          <b>2.</b>&ensp;He would like some <input id=1 type='Circle'/> on his pizza.<br>
          <b>3.</b>&ensp;He wants some <input id=2 type='Circle'/> to drink.<br>
          <b>4.</b>&ensp;Mom thinks it isn't <input id=3 type='Circle'/>.<br>
        </div>
        <img style='height:5cm' src='img/FriendsPlus/Page28/E2/1.jpg'/>
      </div>
        
      
      `,
    },
  },
  3: {
    unit: "Fluency Time! 1",
    id: "SB5-2024-FL1-P6-E3",
    // audio: "Audios/Page56/81.mp3",
    video: "",
    recorder: true,
    hintBox: [
      {
        src: [
          "noodles",
          "sandwich",
          "juice",
          "pizza",
          "mushrooms",
          "fish",
          "rice",
          "chicken",
        ],
        width: 999,
      },
    ],
    stylesTextInput: { textAlign: "center", width: 200 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img style='height:8cm' src='img/FriendsPlus/Page28/E3/1.jpg'/>
        
      `,
        answer: [
          "on Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious",
        ],
      },
    ],
  },
};

export default json;
