import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P81-E1",
    audio: "Audios/Page81/122.mp3",
    exerciseKey: "img/FriendsPlus/Page81/Key/E1answerKey.png",
    video: "",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    // inputSize: 200,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid gray",
      // fontSize: 22,
      paddingBottom: 3,
      height: 40,
      width: 35,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write the numbers. <headphone name='122' src='Audios/Page81/122 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative'>
          <img style='height:5cm; margin-top:10px; margin-left: -80px' src='img/FriendsPlus/Page81/E1/1.jpg'/>
          <div style='position: absolute; top: 134px; left: 91px'>#</div>
          <div style='position: absolute; top: 134px; left: 360px'>#</div>
          <div style='position: absolute; top: 134px; left: 895px'>#</div>
        </div>
        
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["2", "4", "3"],
      },
    ],
  },
  2: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P81-E2",
    audio: "Audios/Page81/122.mp3",
    exerciseKey: "img/FriendsPlus/Page81/Key/E2answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 140,
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen again and write <i>T (true)</i> or <i>F (false)</i>. <headphone name='122' src='Audios/Page81/122 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    questions: [
      {
        title: `
        <div style='line-height: 50px'>
        <div style='display: flex; margin-bottom: 12px'><b>1.</b>&ensp;Joe walks to school every day.&ensp; <u style='color: rgb(54,166,215)'>&ensp;T&ensp;</u></div>
          <div style='display: flex'><b>2</b>&ensp;Sarah is healthy now.&ensp; <select id=0></select></div>
          <div style='display: flex'><b>3</b>&ensp;Ella doesn't like water.&ensp; <select id=1></select></div>
          <div style='display: flex'><b>4</b>&ensp;Pete never eats candy.&ensp; <select id=2></select></div>
        </div>
          
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["T", "F", "F"],
      },
    ],
  },
  3: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P81-E3",
    // audio: "Audios/Page64/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 100,
    },
    titleQuestion: [
      {
        num: "3",
        title: "What should you do to be healthy? Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page81/E3/1.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
    ],
  },
  4: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P81-E4",
    // audio: "Audios/Page64/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 150,
      marginBottom: 40,
    },
    titleQuestion: [
      {
        num: "4",
        title: "Describe how to live a healthy life.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page81/E4/1.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
    ],
  },
  5: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P81-E5",
    // audio: "Audios/Page81/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page81/Key/E5answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    // inputSize: 200,
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      paddingTop: 5,
      marginLeft: 30,
      // height: 70,
    },
    titleQuestion: [
      {
        num: "5",
        title: "Write about your healthy habits. Use the example to help you.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <div style='margin-left: -0px'><img style='height:10cm;margin-top:10px' src='img/FriendsPlus/Page81/E5/1.jpg'/><br></div>
          <textarea id='0' rows='8'>
        </div>
        
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [""],
      },
    ],
  },
};

export default json;
