import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P60-E1",
    audio: "Audios/Page60/87.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='87' src='Audios/Page60/87 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page60/E1/1.jpg",
          audioUrl: "Audios/Page60/lake.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/3.jpg",
          audioUrl: "Audios/Page60/mountain.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/5.jpg",
          audioUrl: "Audios/Page60/waterfall.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/7.jpg",
          audioUrl: "Audios/Page60/ocean.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E1/9.jpg",
          audioUrl: "Audios/Page60/wide.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/11.jpg",
          audioUrl: "Audios/Page60/deep.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/13.jpg",
          audioUrl: "Audios/Page60/high.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/15.jpg",
          audioUrl: "Audios/Page60/island.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P60-E2",
    audio: "Audios/Page60/88.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='88' src='Audios/Page60/88 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page60/E1/1.jpg",
          audioUrl: "Audios/Page60/lake.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/3.jpg",
          audioUrl: "Audios/Page60/mountain.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/5.jpg",
          audioUrl: "Audios/Page60/waterfall.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/7.jpg",
          audioUrl: "Audios/Page60/ocean.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E1/9.jpg",
          audioUrl: "Audios/Page60/wide.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/11.jpg",
          audioUrl: "Audios/Page60/deep.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/13.jpg",
          audioUrl: "Audios/Page60/high.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/15.jpg",
          audioUrl: "Audios/Page60/island.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P60-E3",
    audio: "Audios/Page60/89.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 8.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 70,
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. Why does Amy run fast? <headphone name='89' src='Audios/Page60/89 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page60/E3/1.jpg",
          audioUrl: "Audios/Page60/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/2.jpg",
          audioUrl: "Audios/Page60/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E3/3.jpg",
          audioUrl: "Audios/Page60/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/4.jpg",
          audioUrl: "Audios/Page60/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
