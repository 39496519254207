import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P66-E1",
    audio: "Audios/Page66/97.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='97' src='Audios/Page66/97 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page66/E1/1.jpg",
          audioUrl: "Audios/Page66/path.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/3.jpg",
          audioUrl: "Audios/Page66/grass.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/5.jpg",
          audioUrl: "Audios/Page66/flowers.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/7.jpg",
          audioUrl: "Audios/Page66/garbage can.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E1/9.jpg",
          audioUrl: "Audios/Page66/trees.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/11.jpg",
          audioUrl: "Audios/Page66/fountain.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/13.jpg",
          audioUrl: "Audios/Page66/litter.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/15.jpg",
          audioUrl: "Audios/Page66/bushes.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P66-E2",
    audio: "Audios/Page66/98.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='98' src='Audios/Page66/98 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page66/E1/1.jpg",
          audioUrl: "Audios/Page66/path.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/3.jpg",
          audioUrl: "Audios/Page66/grass.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/5.jpg",
          audioUrl: "Audios/Page66/flowers.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/7.jpg",
          audioUrl: "Audios/Page66/garbage can.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E1/9.jpg",
          audioUrl: "Audios/Page66/trees.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/11.jpg",
          audioUrl: "Audios/Page66/fountain.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/13.jpg",
          audioUrl: "Audios/Page66/litter.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page66/E1/15.jpg",
          audioUrl: "Audios/Page66/bushes.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P66-E3",
    audio: "Audios/Page66/99.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 9.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 70,
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. Why did Max fall into the fountain? <headphone name='99' src='Audios/Page66/99 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page66/E3/1.jpg",
          audioUrl: "Audios/Page66/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/2.jpg",
          audioUrl: "Audios/Page66/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E3/3.jpg",
          audioUrl: "Audios/Page66/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/4.jpg",
          audioUrl: "Audios/Page66/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
