import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P46-E1",
    audio: "Audios/Page46/68.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='68' src='Audios/Page46/68 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page46/E1/1.jpg",
          audioUrl: "Audios/Page46/receive.mp3",
        },
        {
          url: "img/FriendsPlus/Page46/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page46/E1/3.jpg",
          audioUrl: "Audios/Page46/lucky money.mp3",
        },
        {
          url: "img/FriendsPlus/Page46/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page46/E1/5.jpg",
          audioUrl: "Audios/Page46/fireworks.mp3",
        },
        {
          url: "img/FriendsPlus/Page46/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page46/E1/7.jpg",
          audioUrl: "Audios/Page46/enjoy.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P46-E2",
    // audio: "Audios/Page46/E2/audio-e2.mp3",
    video: "",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { width: 550 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Look at the text. What can you see in the pictures?",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:15cm' src='img/FriendsPlus/Page46/E3/1.jpg'/><br>
                  <div>
                  What can you see in the pictures?<br>#
                  </div>
                  
        
      `,
        answer: ["s", "es", "s", "s", "s", "es"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P46-E1",
    audio: "Audios/Page46/69.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and Read <headphone name='69' src='Audios/Page46/69 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 100,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page46/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P46-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page46/Key/E4answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    // maxLength: 1,
    stylesTextInput: { width: 540 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Read again and answer the questions.",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:16cm' src='img/FriendsPlus/Page46/E3/1.jpg'/><br>
              <div>
                <b>1</b>&ensp;Who was Oanh with last Tet?<br>#<br>
                <b>2</b>&ensp;Where did her grandparents live 30 years ago?<br>#<br>
                <b>3</b>&ensp;What did the children receive?<br>#<br>
                <b>4</b>&ensp;What food did they cook?<br>#<br>
                <b>5</b>&ensp;What did they do in the evening?<br>#<br>
                <b>6</b>&ensp;Did they enjoy Tet?<br>#<br>
              </div>
        
      `,
        //notSymbol: true,
        answer: [
          "She was with her grandparents.",
          "They lived in the city.",
          "They received lucky money.",
          "They cooked banh chung.",
          "They watched fireworks.",
          "Yes, they did.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P46-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page46/Key/E5answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    textareaStyle: { paddingTop: 3, width: 650 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "5",
        title: "Do you like Tet? What did your family do last Tet",
        color: "#203c8f",
        // numberImg: ["b"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <textarea id=0 rows=5></textarea>
        
      `,
        answer: [""],
      },
    ],
  },
};

export default json;
