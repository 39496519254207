import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Review 2",
    id: "SB5-2024-R2-P48-E1",
    audio: "Audios/Page48/71.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page48/Key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and match. <headphone name='71' src='Audios/Page48/71 tieude.mp3'></headphone>",
        color: "#203c8f",
        width: "24cm",
        // left: -50,
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "76px",
              left: "81px",
              width: "33px",
              height: "32px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "214px",
              left: "81px",
              width: "33px",
              height: "32px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "352px",
              left: "81px",
              width: "33px",
              height: "32px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "490px",
              left: "81px",
              width: "33px",
              height: "32px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "628px",
              left: "81px",
              width: "33px",
              height: "32px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "77px",
              left: "490px",
              width: "211px",
              height: "148px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "490px",
              width: "211px",
              height: "148px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "629px",
              left: "490px",
              width: "211px",
              height: "148px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "802px",
              width: "211px",
              height: "148px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "490px",
              left: "802px",
              width: "211px",
              height: "148px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 9
        ],
        answers: ["0-7", "1-6", "2-5", "3-9", "4-8"],
        initialValue: ["0-7"],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "What's in the boxes|?",
          "What's in there|?",
          "Are there some cakes|?",
          "Or some cookies to share|?",
          "Are there some sandwiches|?",
          "Or some balloons|?",
          "Look|! Masks and hats|!",
          "The party's this afternoon|.",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <img style='height:19cm;margin-left:50px' src='img/FriendsPlus/Page48/E1/1.jpg'/>
        <input id=0 type='boxMatch'/>
        <input id=1 type='boxMatch'/>
        <input id=2 type='boxMatch'/>
        <input id=3 type='boxMatch'/>
        <input id=4 type='boxMatch'/>
        <input id=5 type='boxMatch'/>
        <input id=6 type='boxMatch'/>
        <input id=7 type='boxMatch'/>
        <input id=8 type='boxMatch'/>
        <input id=9 type='boxMatch'/>
        
      
      `,
    },
  },
  2: {
    unit: "Review 2",
    id: "SB5-2024-R2-P48-E2",
    audio: "Audios/Page48/72.mp3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E2answerKey.png",
    video: "",
    hintBox: [
      {
        src: [
          "get up",
          "brush my teeth",
          "start school",
          "finish school",
          "do homework",
          "take a shower",
        ],
        width: 1119,
      },
    ],
    maxLength: 1,
    stylesTextInput: { textAlign: "center", width: 40 },
    // isHello: true,
    // KeyMau: true,
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and write <i>T</i>(true) or <i>F</i>(false). <headphone name='72' src='Audios/Page48/72 tieude.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["b"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <div style='line-height:47px'>
          <b>1.</b>&ensp;She had an art lesson yesterday.#<br>
          <b>2.</b>&ensp;They weren't at school last week because they were sick.#<br>
          <b>3.</b>&ensp;She was very tall when she was three.#<br>
          <b>4.</b>&ensp;He didn't have his P.E. clothes in his bag this morning.#<br>
          <b>5.</b>&ensp;The plane from Thailand was late today.#<br>
          <b>6.</b>&ensp;They were at home this morning because it was stormy.#<br>
        </div>
      `,
        answer: ["T", "F", "F", "T", "T", "F"],
      },
    ],
  },
};

export default json;
