import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P64-E1",
    audio: "Audios/Page64/94.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "95%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='94' src='Audios/Page64/94 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page64/E1/1.jpg",
          audioUrl: "Audios/Page64/world.mp3",
        },
        {
          url: "img/FriendsPlus/Page64/E1/2.jpg",
          audioUrl: "Audios/Page64/dark.mp3",
        },
        {
          url: "img/FriendsPlus/Page64/E1/3.jpg",
          audioUrl: "Audios/Page64/restaurant.mp3",
        },
        {
          url: "img/FriendsPlus/Page64/E1/4.jpg",
          audioUrl: "Audios/Page64/floor.mp3",
        },
        {
          url: "img/FriendsPlus/Page64/E1/5.jpg",
          audioUrl: "Audios/Page64/building.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P64-E2",
    // audio: "Audios/Page64/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 80,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Look at the text. What can you see in the photos?",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page64/E3/1.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P64-E3",
    audio: "Audios/Page64/95.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E3answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 200,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='95' src='Audios/Page64/95 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        // numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:16.5cm;margin-top:20px' src='img/FriendsPlus/Page64/E3/1.jpg'/>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P64-E4",
    // audio: "Audios/Page64/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E4answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 800,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "4",
        title: "Read again and answer the questions.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:15cm;margin-top:10px' src='img/FriendsPlus/Page64/E3/1.jpg'/>
        <div style='display; flex'>
        <div style='line-height: 40px; font-size: 22px'>
          <div style='display: flex'><b>1</b>&ensp;Is Fansipan mountain higher than Bach Ma mountain?</div> #<br>
          <div style='display: flex'><b>2</b>&ensp;Where is Son Doong cave?</div>#<br>
          <div style='display: flex'><b>3</b>&ensp;How long is the Dong Nai river?</div>#<br>
          <div style='display: flex'><b>4</b>&ensp;Is Keangnam Tower taller than Landmark 81?</div>#<br>
        </div>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "Yes, it is.",
          "It is in Quang Binh province.|It's in Quang Binh province.",
          "It is 586 kilometers long.|It's 586 kilometers long.",
          "No, it is not.|No, it isn't.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P64-E5",
    // audio: "Audios/Page64/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "20%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "5",
        title: "Which place would you like to visit and why?",
        color: "#203c8f",
        left: 250,
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page64/E5/1.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
    ],
  },
};

export default json;
