import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Select_Image from "../../components/ExcerciseTypes/Select/Select_Image";
const json = {
  1: {
    unit: "Starter",
    id: "SB5-2024-Starter-P7-E1",
    exerciseKey: "img/FriendsPlus/Page7/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 180,
    // hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 800,
      fontSize: 20,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Read and write.",
        color: "#203c8f",
        numberImg: ["a"],
        left: 100,
      },
    ],

    questions: [
      {
        title: `
        <div style='position:relative'>
          <img style='height:10cm' src='img/FriendsPlus/Page7/E1/1.jpg'/>
          <div style='position:absolute;top:40px;left:514px'><input id=0  width='484px'></input></div>
          <div style='position:absolute;top:81px;left:532px'><input id=1  width='466px'></input></div>
          <div style='position:absolute;top:122px;left:622px'><input id=2 width='376px'></input></div>
          <div style='position:absolute;top:164px;left:611px'><input id=4 width='387px'></input></div>
          <div style='position:absolute;top:204px;left:615px'><input id=3 width='383px'></input></div>
          <div style='position:absolute;top:245px;left:547px'><input id=5 width='451px'></input></div>
          <div style='position:absolute;top:286px;left:559px'><input id=6 width='439px'></input></div>
        </div>
        
      `,
        answer: [
          "Huy",
          "98 Bui Thi Xuan Street, Da Lat",
          "Nguyen Trai Primary School, Class 5D",
          "Ms. Phuong",
          "P.E.",
          "playing basketball",
          "P.E. teacher",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SB5-2024-Starter-P7-E2",
    recorder: true,
    // audio: "Audios/Page57/83.mp3",
    hintBox: [
      {
        src: ["doctor", "pilot", "firefighter", "police officer", "farmer"],
        width: 700,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div>
          <img style='height:10cm' src='img/FriendsPlus/Page7/E2/1.jpg'/>
        </div>
      `,
        answer: [
          "Huy",
          "98 Bui Thi Xuan Street, Da Lat",
          "Nguyen Trai Primary School, Class 5D",
          "Ms. Phuong",
          "P.E.",
          "playing basketball",
          "P.E. teacher",
        ],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "SB5-2024-Starter-P7-E3",
    exerciseKey: "img/FriendsPlus/Page7/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 180,
    hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      width: 200,
      fontSize: 22,
      paddingTop: 10,
    },
    titleQuestion: [
      {
        num: "3",
        title: "Write about you",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],

    questions: [
      {
        title: `
        <div style='background: rgb(196,231,251) ; border-radius:5px; padding: 10px 20px;border: 3px solid rgb(44,122,183)'>
          Hi! My name's # and I live at  #. I'm CLass # at. My teacher's name is#. My favorite 
          subject is # because I want to be a #. I like # in my free time. I'm # now.
        </div>
        
      `,
        answer: [
          "Huy",
          "98 Bui Thi Xuan Street, Da Lat",
          "Nguyen Trai Primary School, Class 5D",
          "Ms. Phuong",
          "P.E.",
          "playing basketball",
          "P.E. teacher",
        ],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB5-2024-Starter-P7-E4",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "4",
        title:
          "Say the sentences. Draw arrows. <img style='height:1cm' src='img/FriendsPlus/ListPage/c.jpg'/>",
        color: "#203c8f",
        numberImg: ["b"],
        left: -30,
      },
    ],
    component: Select_Image,
    exerciseKey: "img/FriendsPlus/Page7/key/E4answerKey.png",

    selectStyle: {
      height: "1cm",
    },
    styleImgSelect: [
      { height: 40, width: 247 },
      { height: 40, width: 150 },
      { height: 40, width: 380 },
      { height: 40, width: 175 },
      { height: 40, width: 315 },
      { height: 40, width: 130 },
      { height: 40, width: 235 },
      { height: 40, width: 125 },
    ],
    selectGroupOption: [
      [`img/FriendsPlus/Page7/E4/z1.jpg`, `img/FriendsPlus/Page7/E4/z1-1.jpg`],
      [`img/FriendsPlus/Page7/E4/z2.jpg`, `img/FriendsPlus/Page7/E4/z2-1.jpg`],
      [`img/FriendsPlus/Page7/E4/z3.jpg`, `img/FriendsPlus/Page7/E4/z3-1.jpg`],
      [`img/FriendsPlus/Page7/E4/z4.jpg`, `img/FriendsPlus/Page7/E4/z4-1.jpg`],
      [`img/FriendsPlus/Page7/E4/z5.jpg`, `img/FriendsPlus/Page7/E4/z5-1.jpg`],
      [`img/FriendsPlus/Page7/E4/z6.jpg`, `img/FriendsPlus/Page7/E4/z6-1.jpg`],
      [`img/FriendsPlus/Page7/E4/z7.jpg`, `img/FriendsPlus/Page7/E4/z7-1.jpg`],
      [`img/FriendsPlus/Page7/E4/z8.jpg`, `img/FriendsPlus/Page7/E4/z8-1.jpg`],
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div><img src='img/FriendsPlus/Page7/E4/a.jpg' alt='' style='height:11cm'/></div>
            <div style='position: absolute; top: 76px; left: 40px;'> <select id='0' group='0'  width='245' height='40' heightImg='500px'></select></div>
            <div style='position: absolute; top: 76px; left: 300px;'><select id='1' group='1'  width='150' height='40'></select></div>
            <div style='position: absolute; top: 159px; left: 40px;'><select id='2' group='2'  width='380' height='40'></select></div>
            <div style='position: absolute; top: 159px; left:435px;'><select id='3' group='3'  width='175' height='40'></select></div>
            <div style='position: absolute; top: 242px; left: 40px;'><select id='4' group='4'  width='315' height='40'></select></div>
            <div style='position: absolute; top: 242px; left:365px;'><select id='5' group='5'  width='135' height='40'></select></div>
            <div style='position: absolute; top: 325px; left: 40px;'><select id='6' group='6'  width='237' height='40'></select></div>
            <div style='position: absolute; top: 325px; left:290px;'><select id='7' group='7'  width='130' height='40'></select></div>

            
          </div>
        `,
        answer: [
          `img/FriendsPlus/Page7/E4/z1.jpg`,
          `img/FriendsPlus/Page7/E4/z2.jpg`,
          `img/FriendsPlus/Page7/E4/z3.jpg`,
          `img/FriendsPlus/Page7/E4/z4.jpg`,
          `img/FriendsPlus/Page7/E4/z5.jpg`,
          `img/FriendsPlus/Page7/E4/z6.jpg`,
          `img/FriendsPlus/Page7/E4/z7.jpg`,
          `img/FriendsPlus/Page7/E4/z8.jpg`,
        ],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "SB5-2024-Starter-P7-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,

    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page7/E5/1.jpg",
        },
      ],
    ],
  },
};

export default json;
