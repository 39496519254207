import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P78-E1",
    // audio: "Audios/Page61/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page78/E1/1.jpg" }]],
  },
  2: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P78-E2",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page78/Key/E2answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 800,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "2",
        title: "Write three sentences.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height: 50px'>
          <div style='display: flex'><span style='color:rgb(54,166,215);'>When you have a headache, you should ...</span></div>
          <div style='display: flex'><b>1.</b>&ensp;#</div>
          <div style='display: flex'><b>2.</b>&ensp;#</div>
          <div style='display: flex'><b>3.</b>&ensp;#</div>
        </div>
      
      
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [],
      },
    ],
  },
  3: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P78-E3",
    audio: "Audios/Page78/117.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "80%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and sing. <headphone name='117' src='Audios/Page78/117 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page78/E3/1.jpg",
          // audioUrl: "Audios/Page78/candy.mp3",
        },
      ],
    ],
  },
  4: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P78-E4",
    // audio: "Audios/Page78/117.mp3",
    video:
      "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/What's the matter, Bobby.mp4",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "80%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "4",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page78/E3/1.jpg",
          // audioUrl: "Audios/Page78/candy.mp3",
        },
      ],
    ],
  },
  5: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P78-E5",
    // audio: "Audios/Page70/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "20%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "5",
        title: "What should you do when you are ill? What shouldn't you do?",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page64/E5/1.jpg",
          // audioUrl: "Audios/Page70/candy.mp3",
        },
      ],
    ],
  },
};

export default json;
