import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Match_Write from "../../components/ExcerciseTypes/Match_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Review 3",
    id: "SB5-2024-R3-P72-E1",
    audio: "Audios/Page72/107.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and check (✓) a or b. There is one example. <headphone name='107' src='Audios/Page72/107 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        width: "25cm",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        Tick: true,
        initialWordStyle: {
          // padding: "1px 8px",
          // border: "2px solid gray",
          // borderRadius: "25%",
          fontWeight: "bold",
          color: "white",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 8px",
          color: "#00aeef",
          // border: "2px solid gray",
          // borderRadius: "25%",
          // borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["✓ <br> ✓", "✓ <br> ✓", "✓ <br> ✓", "✓ <br> ✓", "✓ <br> ✓"],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-4"],
        initialValue: ["0-0"],
      },
      Layout: `
    <div style='position: relative'>

    <div style='display:flex; margin-left: 100px'>
    <div>
        <div style='display:flex;line-height:45px; margin-bottom: 10px'>
            <div><b>1.&ensp;</b></div>
            <div>
              a. Yes, we did. We saw lots of old paintings.<br>
              b. You mustn't take photos in the museum.
            </div>
        </div>

        <div style='display:flex;line-height:45px; margin-bottom: 10px'>
            <div><b>2.&ensp;</b></div>
            <div>
              a. I bought some great postcards.<br>
              b. There weren't any models.
            </div>
        </div>

        <div style='display:flex;line-height:45px; margin-bottom: 10px'>
            <div><b>3.&ensp;</b></div>
            <div>
              a. I'm not sure.<br>
              b. It's a very large cave.
            </div>
        </div>
        
        <div style='display:flex;line-height:45px; margin-bottom: 10px'>
            <div><b>4.&ensp;</b></div>
            <div>
              a. I'm playing volleyball.<br>
              b. No, you mustn't play on the grass.
            </div>
        </div>

        <div style='display:flex;line-height:45px; margin-bottom: 10px'>
            <div><b>5.&ensp;</b></div>
            <div>
              a. Mai is running fast.<br>
              b. I think Mai is faster.
            </div>
        </div>
        </div>

        <div>
        
        </div>
        <div style='line-height: 45px; margin-left: 565px; position: absolute; z-index: 1'>
          <div style='margin-bottom: 10px'>
            <input id=0 type='Circle' />
          </div>
          <div style='margin-bottom: 10px'>
            <input id=1 type='Circle' />
          </div>
          <div style='margin-bottom: 10px; '>
            <input id=2 type='Circle' />
          </div>
          <div style='margin-bottom: 10px'>
            <input id=3 type='Circle' />
          </div>
          <div>
            <input id=4 type='Circle' />
          </div>
        </div>
        <div style='position: absolute; top: -2px; left: 638px; z-index: 0'>
          <img style='height:13.5cm' src='img/FriendsPlus/Page72/E1/1.png'/>
        </div>

    </div>
      
      `,
    },
  },

  2: {
    unit: "Review 3",
    id: "SB5-2024-R3-P72-E2",
    // audio: "Audios/Page72/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: "Read and circle the correct word.",
        color: "#203c8f",
        width: "25cm",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "2px 8px",
          // border: "2px solid gray",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          padding: "2px 8px",
          color: "#00aeef",
          border: "2px solid gray",
          borderRadius: "50%",
          // borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },

        limitSelect: 1,
        listWords: [
          "a.&ensp;train <br> b.&ensp;skateboard <br> c.&ensp;plane",
          "a.&ensp;wide <br> b.&ensp;high <br> c.&ensp;interesting",
          "a.&ensp;bones <br> b.&ensp;clothes <br> c.&ensp;transport",
          "a.&ensp;wider <br> b.&ensp;older <br> c.&ensp;longer",
          "a.&ensp;scientist <br> b.&ensp;firefighter <br> c.&ensp;police_office",
          "a.&ensp;ate <br> b.&ensp;buy <br> c.&ensp;bought",
        ],
        answers: ["0-0", "1-8", "2-0", "3-4", "4-0", "5-8"],
        initialValue: ["0-0"],
      },
      Layout: `
      <img style='height:8cm' src='img/FriendsPlus/Page72/E2/1.jpg'/>
      <div style='display:flex; margin-left: 10px; justify-content: space-around'>
      <div>
        <div style='display: flex'>
          <div><b>1.&ensp;</b></div>
          <div><input id=0 type='Circle' /></div>
        </div>
        <div style='display: flex'>
          <div><b>2.&ensp;</b></div>
          <div><input id=1 type='Circle' /></div>
        </div>
        <div style='display: flex'>
          <div><b>3.&ensp;</b></div>
          <div><input id=2 type='Circle' /></div>
        </div>
        
      </div>
      <div style=''>
        <div style='display: flex'>
          <div><b>4.&ensp;</b></div>
          <div><input id=3 type='Circle' /></div>
        </div>
        <div style='display: flex'>
          <div><b>5.&ensp;</b></div>
          <div><input id=4 type='Circle' /></div>
        </div>
        <div style='display: flex'>
          <div><b>6.&ensp;</b></div>
          <div><input id=5 type='Circle' /></div>
        </div>
        
      </div>
      </div>
      
      `,
    },
  },

  // 4: {
  //   unit: 'Review 3',
  //   id: 'SB5-2024-R3-P72-E1',
  //   audio: '',
  //   video: '',
  //   component: Match_Write,
  //   exerciseKey: 'img/FriendsPlus/Page72/E4/Key/answerKey.png',
  //   titleQuestion: [
  //     {
  //       num: '',
  //       title: "<img src='img/FriendsPlus/Page72/E4/15.jpg' /> <br> <img src='img/FriendsPlus/Page72/E4/1.jpg' /> ",
  //       color: '',
  //     },
  //   ],
  //   question: {
  //     Match: {
  //       answers: ['0-5', '1-4', '2-7', '3-6'],
  //       coordinates: [
  //         { x: 222, y: 200 },
  //         { x: 444, y: 200 },
  //         { x: 666, y: 200 },
  //         { x: 888, y: 200 },
  //         { x: 222, y: 400 },
  //         { x: 444, y: 400 },
  //         { x: 666, y: 400 },
  //         { x: 888, y: 400 },
  //       ],
  //       // height: 600,
  //       isHorizontal: false,
  //       width: 1110,
  //     },
  //     Write: {
  //       answers: ['hello', 'hi', 'now', 'future'],
  //       commonStyles: {},
  //     },
  //     Layout: `
  //       <div>
  //       <input id='0' />
  //       <input id='1' />
  //       <canvas></canvas>
  //       <input id='2' />
  //       <input id='3' width='50px' />
  //       </div>
  //     `,
  //   },
  // },
};
export default json;
