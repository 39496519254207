import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P15-E1",
    audio: "Audios/Page14/18.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 2.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='18' src='Audios/Page14/18 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page14/E3/1.jpg",
          audioUrl: "Audios/Page14/18 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E3/2.jpg",
          audioUrl: "Audios/Page14/18 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page14/E3/3.jpg",
          audioUrl: "Audios/Page14/18 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E3/4.jpg",
          audioUrl: "Audios/Page14/18 04.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P15-E2",
    audio: "Audios/Page15/19.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='19' src='Audios/Page15/19 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page15/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page15/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page15/E2/3.jpg",
          audioUrl: "Audios/Page15/19 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page15/E2/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page15/E2/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page15/E2/6.jpg",
          audioUrl: "Audios/Page15/19 02.mp3",
        },
        {
          url: "img/FriendsPlus/Page15/E2/7.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P15-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page15/Key/E3answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    maxLength: 1,
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 40 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Read and Write T(true) or F(false).",
        color: "#203c8f",
        numberImg: ["b"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex'>
          <div>
            <div>
              <img style='height:4cm' src='img/FriendsPlus/Page15/E3/1.jpg'/><br>
              Bao always rides his bike. #
            </div>
            <div>
              <img style='height:4cm' src='img/FriendsPlus/Page15/E3/2.jpg'/><br>
              They sometimes watch TV. #
            </div>
          </div>
          <div style='margin-left:50px'>
            <div>
              <img style='height:4cm' src='img/FriendsPlus/Page15/E3/3.jpg'/><br>
              Nam often takes photos. #
            </div>
            <div>
              <img style='height:4cm' src='img/FriendsPlus/Page15/E3/4.jpg'/><br>
              Mom never listen to music. #
            </div>
          </div>
        </div>
        
      `,
        // initialValue: ["T", "", "", ""],
        answer: ["T", "F", "T", "F"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P15-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page15/Key/E4answerKey.png",
    // styleHint: {
    //   border: "none",
    //   paddingInline: 3,
    //   paddingBlock: "0px",
    //   styleElementHintBox: {
    //     background: "#fee7d2",
    //     marginRight: 20,
    //     padding: "2px 5px",
    //     borderRadius: "10px",
    //   },
    // },
    hintBox: [
      {
        src: ["on", "in", "at"],
        width: 216,
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 70 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Write",
        color: "#203c8f",
        numberImg: ["b"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
              <hintbox id=0 ></hintbox>
              <div style='margin-top:20px'>
                <b>1</b>&ensp;Mais's birthday is # March.<br>
                <b>2</b>&ensp;I usually have lunch # one o'clock.<br>
                <b>3</b>&ensp;Dad never works # Saturdays.<br>
                <b>4</b>&ensp;We sometimes go to the beach # August.<br>
              </div>
        
      `,
        answer: ["in", "at", "on", "in"],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P15-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 778,
      paddingTop: 5,
    },
    inputSize: 720,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title: "Write a tourist leaflet. Use the example to help you.",
    //     color: "#203c8f",
    //     numberImg: "a",
    //     left: 80,
    //   },
    // ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img style='height:10cm' src='img/FriendsPlus/Page15/E5/1.jpg'/>
				  
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
