import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P83-E1",
    audio: "Audios/Page83/125.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 11.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='125' src='Audios/Page83/125 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page82/E3/1.jpg",
          audioUrl: "Audios/Page82/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/2.jpg",
          audioUrl: "Audios/Page82/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E3/3.jpg",
          audioUrl: "Audios/Page82/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/4.jpg",
          audioUrl: "Audios/Page82/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P83-E2",
    audio: "Audios/Page83/126.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "80%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='126' src='Audios/Page83/126 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page83/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page83/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page83/E2/3.jpg",
          // audioUrl: "Audios/Page83/126.mp3",
        },
        {
          url: "img/FriendsPlus/Page83/E2/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page83/E2/5.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P83-E3",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page83/Key/E3answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 170,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      // fontSize: 22,
      height: 27,
      textTransform: "lowercase",
      // borderStyle: "dotted",
      textAlign: "center",
    },
    // hintBox: [
    //   {
    //     src: ["fast", "polite", "hard", "neat", "loud", "<s>good</s>"],
    //     width: 682,
    //   },
    // ],
    titleQuestion: [
      {
        num: "3",
        title: "Read and write.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    // selectStyle: {
    //   height: 35,
    //   width: 35,
    //   textAlign: "center",
    // },
    // selectOptionRandom: true,
    // selectOptionValues: ["T", "F"],
    questions: [
      {
        title: `
        <div style='display: flex; margin-left: -20px'>
        <div style='width: 16cm; line-height: 45px'>In the future, maybe people <sup><b>1</b></sup><u style='color: rgb(54,166,215) '>&ensp;will&ensp;</u> go to the moon for vacations.
         They <sup><b>2</b></sup># travel by rocket or spaceship. They <sup><b>3</b></sup># travel by plane.
          They <sup><b>4</b></sup># wear astronauts's clothes. They <sup><b>5</b></sup># eat space food through a tube. 
          But they <sup><b>6</b></sup># get out of the spaceship. It <sup><b>7</b></sup># be too dangerous.
        
        </div>
        <img style='height:7cm;' src='img/FriendsPlus/Page83/E3/1.jpg'/>
        </div>
          
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["will", "won't", "will", "will", "won't", "will"],
      },
    ],
  },
  4: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P83-E4",
    // audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page83/E4/Key/answerKey.png",
    // maxLength: 32,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: "Look at exercise 3 again. Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    textAlign: "center",
    hideBtnFooter: true,
    inputSize: 130,

    // isHello: true,
    questions: [
      {
        title: `
        <div style='text-align: center; margin-left: -100px'>
        <div>
        <img style='height:6.5cm;margin-top:0px' src='img/FriendsPlus/Page83/E4/1.jpg'/>
        </div>
        <div>
        <img style='height:11.5cm; margin-top: -50px' src='img/FriendsPlus/Page83/E4/2.png'/>
        </div>
        </div>
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P83-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    styleContent: {
      width: "40%",
      marginLeft: 240,
    },
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page83/E5/1.jpg" }]],
  },
};

export default json;
