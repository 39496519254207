import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P39-E1",
    recorder: true,
    audio: "Audios/Page39/56.mp3",
    // video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 01.mp4",
    component: UI,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen,point, and repeat. <headphone name='56' src='Audios/Page39/56 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "85%",
      marginLeft: 70,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page39/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page39/E1/2.jpg",
          audioUrl: "Audios/Page39/hand.mp3",
        },
        {
          url: "img/FriendsPlus/Page39/E1/3.jpg",
          audioUrl: "Audios/Page39/pond.mp3",
        },
        {
          url: "img/FriendsPlus/Page39/E1/4.jpg",
          audioUrl: "Audios/Page39/plant.mp3",
        },

        {
          url: "img/FriendsPlus/Page39/E1/5.jpg",
          audioUrl: "Audios/Page39/tent.mp3",
        },
        {
          url: "img/FriendsPlus/Page39/E1/6.jpg",
          audioUrl: "Audios/Page39/lamp.mp3",
        },

        {
          url: "img/FriendsPlus/Page39/E1/7.jpg",
          audioUrl: "Audios/Page39/camp.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P39-E2",
    audio: "Audios/Page39/57.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='57' src='Audios/Page39/57 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 50,
      },
    ],
    styleContent: {
      width: "75%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page39/E2/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P39-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page39/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Underline the words that end with <i>nd, nt,</i> and <i>mp</i>. Then say. ",
        color: "#203c8f",
        width: "25cm",
        left: -50,
        numberImg: ["b", "c"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "We put up the tent|,",
          "At the big, big camp|.",
          "We hear the wind|.",
          "We light the lamp|.",
          "We sit by the pond|.",
          "We look at the plants|.",
          "We're happy together|,",
          "just me and my aunt|.",
        ],
        answers: ["0-8", "1-8", "2-6", "3-6", "4-8", "7-8", "7-4"],
        initialValue: ["0-8"],
      },
      Layout: `
      <div style='position: relative'>
        <img style='height:10cm;margin-top:20px' src='img/FriendsPlus/Page39/E3/1.jpg'/>
        <div style=' position: absolute; top: 58px; font-size: 23px; left: 767px; text-Align:center'>
          <div><input id=0 type='Circle' /></div>
          <div><input id=1 type='Circle' /></div>
          <div><input id=2 type='Circle' /></div>
          <div><input id=3 type='Circle' /></div>
        </div>
        <div style=' position: absolute; top: 214px; font-size: 23px; left: 758px; text-Align:center'>
          <div><input id=4 type='Circle' /></div>
          <div><input id=5 type='Circle' /></div>
          <div><input id=6 type='Circle' /></div>
          <div><input id=7 type='Circle' /></div>
        </div>
      </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P39-E4",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page39/Key/E4answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 150 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write the words. Circle the end letters <i>nd, nt,</i> and <i>mp</i>. Then say",
        color: "#203c8f",
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
              <div style='display:flex; text-align:center'>
                <div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page39/E4/1.jpg'/><br>
                    #
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page39/E4/2.jpg'/><br>
                    #
                  </div>
                </div>
                <div style='margin-left:50px'>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page39/E4/3.jpg'/><br>
                    #
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page39/E4/4.jpg'/><br>
                    #
                  </div>
                </div>
                <div style='margin-left:50px'>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page39/E4/5.jpg'/><br>
                    #
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page39/E4/6.jpg'/><br>
                    #
                  </div>
                </div>
              </div>
        
      `,
        answer: ["plant", "pond", "hand", "tent", "lamp", "camp"],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P39-E5",
    // audio: "img/FriendsPlus/Page390/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page39/E5/1.jpg" }]],
  },
};

export default json;
