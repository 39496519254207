import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P56-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the picture. Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 170,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page56/E1/1.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P56-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 500,
    hideBtnFooter: true,
    textareaStyle: { width: 800, paddingTop: 5 },
    titleQuestion: [
      {
        num: "2",
        title: "Write three sentences about what you and your friend did.",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],

    questions: [
      {
        title: `
      <span style='color:rgb(35,133,198)'>My friends and I went to the museum last Sunday. Nam saw an old train.</span>
      <textarea id='0' rows=10></textarea>  
      
      
        
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P56-E3",
    audio: "Audios/Page56/81.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and sing. <headphone name='81' src='Audios/Page56/81 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 130,
    },
    hideFooter: true,
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page56/E3/1.jpg",
        },
      ],
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P56-E4",
    audio: "",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/The school trip.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "4",
        title: "Sing and do.",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 130,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page56/E3/1.jpg",
        },
      ],
    ],
  },
};

export default json;
