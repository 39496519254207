import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Starter",
    id: "SB5-2024-Starter-P5-E1",
    // audio: "Audios/Page5/79.mp3",
    // video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit  7.mp4",
    recorder: true,
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 100,
    hintBox: [
      {
        src: ["English", "math", "P.E", "art", "Vietnamese"],
        width: 582,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: "Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <hintbox id=0></hintbox>
        </div>
       
      <img style='height:10cm;margin-top:20px' src='img/FriendsPlus/Page5/E1/1.jpg'/>
          
        
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SB5-2024-Starter-P5-E2",
    audio: "Audios/Page5/3.mp3",
    exerciseKey: "img/FriendsPlus/Page5/Key/E2answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 140,
    hintBox: [
      {
        src: ["English", "math", "P.E", "art", "Vietnamese"],
        width: 582,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and write. <headphone name='03' src='Audios/Page5/3 tieude.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height: 50px'>
          <b>1</b>&ensp;Holly's favorite subject is <input id='0'></input> because she likes <input id='1'></input> math problems.<br>
          <b>2</b>&ensp;Amy's favorite subject is <input id='2'></input> because she likes <input id='3'></input> basketball.<br>
          <b>3</b>&ensp;Max's favorite subject is <input id='4'></input>, but he likes <input id='5'></input>, too.<br>
          <b>4</b>&ensp;Leo's favorite subject is <input id='6'></input> because he likes <input id='7'></input>.<br>
        </div>
       
      <img style='height:10cm;margin-top:20px' src='img/FriendsPlus/Page5/E2/1.jpg'/>
          
        
        `,
        initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "math",
          "doing",
          "P.E",
          "playing",
          "English",
          "painting",
          "P.E",
          "running",
        ],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "SB5-2024-Starter-P5-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page5/Key/E3answerKey.png",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 100,
    hintBox: [
      {
        src: [
          "doing math",
          "fishing",
          "swimming",
          "running",
          "playing",
          "painting",
        ],
        width: 871,
      },
      {
        src: ["What's Holly doing? Is she swimming?"],
        width: 480,
        arrow: true,
        position: 1,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: "Look at the pictures in exercise 2. Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <span><hintbox id=0></hintbox></span>
      <div style='position:relative'>
        <div style='position:absolute;top:7px;left:53px'>
          What's Holly doing? Is she swimming?
        </div>
        <div style='position:absolute;top:29px;left:528px'>
          No, she isn't. She doing math.
        </div>
        <img style='height:22mm' src='img/FriendsPlus/Page5/E3/1.jpg'/>
        <img style='height:10cm' src='img/FriendsPlus/Page5/E2/1.jpg'/>
      </div>
      
        
        `,
        answer: ["saw", "bought", "wrote"],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB5-2024-Starter-P5-E4",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page5/Key/E4answerKey.png",
    component: T6,
    inputSize: 500,
    titleQuestion: [
      {
        num: "4",
        title: "Read the story again. Answer the questions.",
        color: "#203c8f",
        numberImg: "a",
      },
    ],
    // isHello: true,
    questions: [
      {
        title: `

        
      
      <div><b>1.</b> Where did Max, Amy, and Holly go?<br>#</div>
      <div><b>2.</b> What did Amy buy?<br>#</div>
      <div><b>3.</b> What did they see?<br>#</div>
      <div><b>4.</b> What did Amy think when the dinosaur roared?<br>#</div>
      <div><b>5.</b> What did Max know about the dinosaur?<br>#</div>
        
        `,
        answer: [
          "They went to the dinosaur museum.",
          "She bought a dinosaur model.",
          "They saw dinosaur bones.",
          "She thought it was alive.",
          "He knew it was a model.",
        ],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "SB5-2024-Starter-P6-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,

    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page5/E5/1.jpg",
        },
      ],
    ],
  },
};

export default json;
