import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P14-E1",
    audio: "Audios/Page14/16.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='16' src='Audios/Page14/16 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page14/E1/1.jpg",
          audioUrl: "Audios/Page14/cafe.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/3.jpg",
          audioUrl: "Audios/Page14/sports center.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/5.jpg",
          audioUrl: "Audios/Page14/market.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/7.jpg",
          audioUrl: "Audios/Page14/playground.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page14/E1/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page14/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/11.jpg",
          audioUrl: "Audios/Page14/shopping mall.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/13.jpg",
          audioUrl: "Audios/Page14/swimming pool.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/15.jpg",
          audioUrl: "Audios/Page14/movie theater.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/16.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/17.jpg",
          audioUrl: "Audios/Page14/skatepark.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P14-E2",
    audio: "Audios/Page14/17.mp3",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='17' src='Audios/Page14/17 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page14/E1/1.jpg",
          audioUrl: "Audios/Page14/cafe.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/3.jpg",
          audioUrl: "Audios/Page14/sports center.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/5.jpg",
          audioUrl: "Audios/Page14/market.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/7.jpg",
          audioUrl: "Audios/Page14/playground.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page14/E1/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page14/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/11.jpg",
          audioUrl: "Audios/Page14/shopping mall.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/13.jpg",
          audioUrl: "Audios/Page14/swimming pool.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/15.jpg",
          audioUrl: "Audios/Page14/movie theater.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E1/16.jpg",
        },
        {
          url: "img/FriendsPlus/Page14/E1/17.jpg",
          audioUrl: "Audios/Page14/skatepark.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P14-E3",
    audio: "Audios/Page14/18.mp3",
    video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 2.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. What do they all want to do?<headphone name='18' src='Audios/Page14/18 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    hideBtnFooter: true,
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page14/E3/1.jpg",
          audioUrl: "Audios/Page14/18 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E3/2.jpg",
          audioUrl: "Audios/Page14/18 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page14/E3/3.jpg",
          audioUrl: "Audios/Page14/18 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page14/E3/4.jpg",
          audioUrl: "Audios/Page14/18 04.mp3",
        },
      ],
    ],
  },
};

export default json;
