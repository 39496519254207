import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P33-E1",
    recorder: true,
    audio: "Audios/Page33/46.mp3",
    // video: "https://cdn.sachso.edu.vn/videos/faf5-sb-2024/Unit 01.mp4",
    component: UI,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen,point, and repeat. <headphone name='46' src='Audios/Page33/46 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "85%",
      marginLeft: 70,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page33/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page33/E1/2.jpg",
          audioUrl: "Audios/Page33/child.mp3",
        },
        {
          url: "img/FriendsPlus/Page33/E1/3.jpg",
          audioUrl: "Audios/Page33/shield.mp3",
        },
        {
          url: "img/FriendsPlus/Page33/E1/4.jpg",
          audioUrl: "Audios/Page33/field.mp3",
        },

        {
          url: "img/FriendsPlus/Page33/E1/5.jpg",
          audioUrl: "Audios/Page33/belt.mp3",
        },
        {
          url: "img/FriendsPlus/Page33/E1/6.jpg",
          audioUrl: "Audios/Page33/salt.mp3",
        },

        {
          url: "img/FriendsPlus/Page33/E1/7.jpg",
          audioUrl: "Audios/Page33/adult.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P33-E2",
    audio: "Audios/Page33/47.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='47' src='Audios/Page33/47 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 50,
      },
    ],
    styleContent: {
      width: "75%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page33/E2/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P33-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page33/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Underline the words that  end with ld and lt. Then say. ",
        color: "#203c8f",
        width: "25cm",
        left: -50,
        numberImg: ["b", "c"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "A child and an adult|,",
          "Are standing in a field|.",
          "The adult has a belt|.",
          "The child has shield|.",
          "The belt is red|.",
          "The shield is  gray|.",
          "The adult sits down|.",
          "But she child wants to play|.",
        ],
        answers: [
          "0-8",
          "2-8",
          "3-2",
          "2-2",
          "3-6",
          "4-2",
          "5-2",
          "6-2",
          "7-4",
          "1-8",
          "0-2",
        ],
        initialValue: ["0-2"],
      },
      Layout: `
      <div style='position: relative'>
        <img style='height:11cm;margin-top:20px' src='img/FriendsPlus/Page33/E3/1.jpg'/>
        <div style=' position: absolute; top: 67px; font-size: 23px; left: 789px; text-Align:center'>
          <div><input id=0 type='Circle' /></div>
          <div><input id=1 type='Circle' /></div>
          <div><input id=2 type='Circle' /></div>
          <div><input id=3 type='Circle' /></div>
        </div>
        <div style=' position: absolute; top: 229px; font-size: 23px; left: 758px; text-Align:center'>
          <div><input id=4 type='Circle' /></div>
          <div><input id=5 type='Circle' /></div>
          <div><input id=6 type='Circle' /></div>
          <div><input id=7 type='Circle' /></div>
        </div>
      </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P33-E4",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page33/Key/E4answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 40 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Complete the words with <i>ld</i> or <i>lt</i>. Then say",
        color: "#203c8f",
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
              <div style='display:flex; text-align:center'>
                <div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page33/E4/1.jpg'/><br>
                    be#
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page33/E4/2.jpg'/><br>
                    chi#
                  </div>
                </div>
                <div style='margin-left:50px'>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page33/E4/3.jpg'/><br>
                    sa#
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page33/E4/4.jpg'/><br>
                    fie#
                  </div>
                </div>
                <div style='margin-left:50px'>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page33/E4/5.jpg'/><br>
                    shie#
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page33/E4/6.jpg'/><br>
                    adu#
                  </div>
                </div>
              </div>
        
      `,
        answer: ["lt", "ld", "lt", "ld", "ld", "lt"],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P33-E5",
    // audio: "img/FriendsPlus/Page330/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page33/E5/1.jpg" }]],
  },
};

export default json;
